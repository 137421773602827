import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { DetailsPane, OverwriteMainContainer, SingleColumn, StyledLabel } from './OverwriteMain.Styles';
import { useDataSources } from 'customHooks/useDataSources';
import {
  metric,
  detailedDimensionsDetail,
  calculatedMetricsDetail,
  metricFunction,
  queryInput,
  overwriteItemResponse,
} from 'API';
import { CCData, useDataQuery } from 'customHooks/useDataQuery';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { platformLogoSelector } from 'methods/platformLogoSelector';
import { set } from 'lodash';
import { DarkButton } from 'components/style/Global';
import { prepQuery } from 'methods/prepQuery';
import AuthContext from 'context/AuthContext';
import ResultPage from './OvervwriteResultPage';
import { TxtContainer } from 'components/Main/Main.styles';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import { API } from 'aws-amplify';
import { getOverwriteItems } from 'graphql/queries';
import APIContext from 'context/APIContext';

export type placeholderMetrics = {
  metric?: string;
  description?: string | null | undefined;
  view?: (string | null)[] | null | undefined;
  isSelected?: boolean;
  detailedMetric?: metric;
};

export type placeholderDimensions = {
  dimension?: string;
  description?: string | null | undefined;
  view?: (string | null)[] | null | undefined;
  isSelected?: boolean;
  detailedDimension?: detailedDimensionsDetail;
};

export type placeholderFullSources = {
  isSelected?: boolean;
  source_name?: string;
  metrics?: placeholderMetrics[];
  dimensions?: placeholderDimensions[];
  name: string;
  icon?: JSX.Element;
  metaName: string;
  calcMetrics: Array<calculatedMetricsDetail & { isSelected?: boolean }>;
  customConversions?: Array<CCData>;
};

const OverwriteMain = () => {
  const theme = useTheme();
  const { activeCampaign, campaignList } = useCampaignContext();
  const [dataSources] = useDataSources(activeCampaign);
  const [selectedDataSource, setSelectedDataSource] = useState<placeholderFullSources>();
  const { user } = useContext(AuthContext);

  const [userMainQuery, setUserMainQuery] = useState<queryInput>();

  const [mainData, mainQueryStatus, mainError, mainQueryID, mainOL] = useDataQuery({
    query: userMainQuery,
    querySource: 'QUERY',
  });

  useEffect(() => {
    setSelectedDataSource(undefined);
  }, [dataSources]);
  // SELECT SOURCE
  const selectSource = (event: ChangeEvent<HTMLInputElement>) => {
    const clonedDataSource = dataSources.map((el) => structuredClone(el)) as placeholderFullSources[];
    const selected = clonedDataSource.find((el) => el.name === event.target.value);

    if (selected && selected.dimensions) {
      selected.dimensions = selected.dimensions
        .filter((dim) => dim.dimension === 'placement' || dim.dimension === 'placement_id')
        .map((dim) => {
          if (dim.dimension === 'placement' || dim.dimension === 'placement_id') {
            return {
              ...dim,
              isSelected: true,
              detailedDimension: {
                column: dim.dimension as string,
                hidden: false,
                id: Math.floor(Math.random() * Date.now()).toString(),
                name: dim.dimension as string,
              },
            };
          }
          return dim;
        });
    }
    if (selected && selected.metrics) {
      const findimpressions = selected.metrics.find((met) => met.metric === 'impressions');
      selected.metrics = selected.metrics
        .filter((met) => /* met.metric === 'impressions' ||  */ met.metric === 'source_impressions')
        .concat({ ...findimpressions, metric: 'tmp_impressions' })
        .map((met) => {
          if (
            /*   met.metric === 'impressions' || */
            met.metric === 'source_impressions' ||
            met.metric === 'tmp_impressions'
          ) {
            return {
              ...met,
              isSelected: true,
              detailedMetric: {
                column: met.metric as string,
                name: met.metric as string,
                aggregation: metricFunction.SUM,
                id: Math.floor(Math.random() * Date.now()).toString(),
                decimals: 2,
              },
            };
          }
          return met;
        });
    }
    if (selected) {
      selected.isSelected = true;
    }
    if (selected && selected.calcMetrics) {
      selected.calcMetrics = selected.calcMetrics.map((met) => {
        return {
          ...met,
          isSelected: false,
        };
      });
    }

    setSelectedDataSource(selected);
  };

  // SELECT DIMENSION
  const selectDimension = (event: ChangeEvent<HTMLInputElement>) => {
    const addDimension = () => {
      const detailedDimension = {
        column: event.target.name,
        hidden: false,
        id: Math.floor(Math.random() * Date.now()).toString(),
        name: event.target.name,
      };

      const newDimensions = selectedDataSource?.dimensions?.map((el) => {
        if (el.dimension === event.target.value) {
          return {
            ...el,
            isSelected: true,
            detailedDimension: detailedDimension,
          };
        } else {
          return el;
        }
      });
      selectedDataSource &&
        setSelectedDataSource({
          ...selectedDataSource,
          dimensions: newDimensions,
        });
    };

    const removeDimension = () => {
      const newDimensions = selectedDataSource?.dimensions?.map((el) => {
        if (el.dimension === event.target.value) {
          delete el.detailedDimension;
          return {
            ...el,
            isSelected: false,
          };
        } else {
          return el;
        }
      });
      selectedDataSource &&
        setSelectedDataSource({
          ...selectedDataSource,
          dimensions: newDimensions,
        });
    };

    event.target.checked ? addDimension() : removeDimension();
  };
  // SELECT METRIC
  const selectMetric = (event: ChangeEvent<HTMLInputElement>) => {
    const addMetric = () => {
      const detailedMetric: metric = {
        column: event.target.name === 'End date' || event.target.name === 'Start date' ? 'date' : event.target.name,
        name: event.target.name,
        aggregation:
          event.target.name.endsWith('_reach') ||
          event.target.name.startsWith('sub_rf_') ||
          event.target.name === 'effective_frequency' ||
          event.target.name === 'reach' ||
          event.target.name === 'frequency' ||
          event.target.name === 'date' ||
          event.target.name === 'End date'
            ? metricFunction.MAX
            : event.target.name === 'Start date'
            ? metricFunction.MIN
            : metricFunction.SUM,
        id: Math.floor(Math.random() * Date.now()).toString(),
        decimals: event.target.name === 'Start date' || event.target.name === 'End date' ? -1 : 2,
      };

      const newMetrics = selectedDataSource?.metrics?.map((el) => {
        if (el.metric === event.target.value) {
          return {
            ...el,
            isSelected: true,
            detailedMetric: detailedMetric,
          };
        } else {
          return el;
        }
      });
      selectedDataSource &&
        setSelectedDataSource({
          ...selectedDataSource,
          metrics: newMetrics,
        });
    };
    const removeMetric = () => {
      const newMetrics = selectedDataSource?.metrics?.map((el) => {
        if (el.metric === event.target.value) {
          delete el.detailedMetric;
          return {
            ...el,
            isSelected: false,
          };
        } else {
          return el;
        }
      });
      selectedDataSource &&
        setSelectedDataSource({
          ...selectedDataSource,
          metrics: newMetrics,
        });
    };
    event.target.checked ? addMetric() : removeMetric();
  };

  //PREP USER QUERY ON FETCH
  const prepUserQuery = () => {
    const selectedPlatform = selectedDataSource;
    delete selectedPlatform?.icon;
    const q = prepQuery(selectedPlatform as placeholderFullSources, false, activeCampaign, user);
    //SET MAIN QUERY
    console.log('QUERY', q);
    setUserMainQuery(q);
  };
  const [fetchLatestData] = useGetLatestData(selectedDataSource);

  // const fakeGetLatestData: overwriteItemResponse[] = [
  //   {
  //     ItemId: '380469734',
  //     ItemName: 'item1',
  //     Metric: 'impressions',
  //     NewValue: 213201,
  //     Reason: 'reason1',
  //     SourceName: 'source1',
  //     __typename: 'overwriteItemResponse',
  //   },
  //   {
  //     ItemId: '380471624',
  //     ItemName: 'item2',
  //     Metric: 'impressions',
  //     NewValue: 115104,
  //     Reason: 'reason2',
  //     SourceName: 'source2',
  //     __typename: 'overwriteItemResponse',
  //   },
  // ];
  const style = {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    // -ms-transform: "translate(-50%, -50%)",
    transform: 'translate(-50%, -50%)',
  };
  if (!activeCampaign) return <div>NO CAMPAIGN</div>;
  if (!dataSources || dataSources.length === 0) {
    return (
      <TxtContainer style={style as React.CSSProperties}>
        <ReactLoading type="spinningBubbles" color={theme.publishButton} />
      </TxtContainer>
    );
  }
  if (dataSources.length > 0 && mainQueryStatus === 'RUNNING') {
    return (
      <TxtContainer style={style as React.CSSProperties}>
        <ReactLoading type="spinningBubbles" color={theme.publishButton} />
      </TxtContainer>
    );
  }
  if (mainData && mainQueryStatus === 'DONE') {
    return (
      <ResultPage
        setUserMainQuery={setUserMainQuery}
        mainData={mainData}
        selectedDataSource={selectedDataSource}
        latestChangesArray={'THIS IS A PLACEHOLDER'}
        overwrittenItems={fetchLatestData}
      ></ResultPage>
    );
  }

  return (
    <OverwriteMainContainer>
      <SingleColumn>
        <h1 className="header">Plaform</h1>
        {dataSources.map((source) => (
          <StyledLabel key={source.name}>
            <input
              type="radio"
              name="dataSource"
              value={source.name}
              onChange={(event) => selectSource(event)}
              id={source.name}
            />
            <div className="checked">
              {/* <span className="icon">{source.name}</span> */}
              <span>{source.name}</span>
            </div>
          </StyledLabel>
        ))}
      </SingleColumn>
      <SingleColumn>
        <h1 className="header">Dimensions</h1>
        {selectedDataSource && (
          <div className="elementsContainer">
            {selectedDataSource.dimensions?.map((dim) => (
              <StyledLabel key={dim.dimension}>
                <input
                  type="checkbox"
                  name={dim.dimension}
                  value={dim.dimension}
                  id={dim.dimension}
                  checked={dim.isSelected}
                  readOnly
                  //   onChange={(event) => selectDimension(event)}
                />
                <div className="checked">
                  <span>{dim.dimension}</span>
                </div>
              </StyledLabel>
            ))}
          </div>
        )}
      </SingleColumn>
      <SingleColumn>
        <h1 className="header">Metrics</h1>
        {selectedDataSource && (
          <div className="elementsContainer">
            {selectedDataSource.metrics?.map((metric) => (
              <StyledLabel key={metric.metric}>
                <input
                  type="checkbox"
                  name={metric.metric}
                  value={metric.metric}
                  id={metric.metric}
                  readOnly
                  checked={metric.isSelected}
                  //   onChange={(event) => selectMetric(event)}
                />
                <div className="checked">
                  <span>{metric.metric}</span>
                </div>
              </StyledLabel>
            ))}
          </div>
        )}
      </SingleColumn>

      <DetailsPane>
        <div className="iconHolder">{platformLogoSelector(selectedDataSource?.name || '')}</div>
        <h1 className="header">Query Overview</h1>
        <div className="details">
          <div className="p">
            {' '}
            <h1>Platform:</h1> {selectedDataSource?.name}
          </div>
          <div className="p">
            {' '}
            <h1>Dimensions:</h1>{' '}
            {selectedDataSource?.dimensions?.filter((d) => d.isSelected).map((d) => d.dimension + ', ')}
          </div>
          <div className="p">
            {' '}
            <h1>Metrics:</h1>
            {selectedDataSource?.metrics?.filter((m) => m.isSelected).map((m) => m.metric + ', ')}{' '}
          </div>
        </div>
        <DarkButton onClick={prepUserQuery}>FETCH</DarkButton>
      </DetailsPane>
    </OverwriteMainContainer>
  );
};
export default OverwriteMain;

export const useGetLatestData = (
  selectedSource: placeholderFullSources | undefined,
): readonly [overwriteItemResponse[] | undefined] => {
  const { getEnvironment } = useContext(APIContext);
  const { activeCampaign } = useCampaignContext();
  const [overwrittenItems, setOverwrittenItems] = useState<overwriteItemResponse[]>();

  const fetchLatestData = async () => {
    try {
      const res = (await API.graphql({
        query: getOverwriteItems,
        variables: {
          CampaignId: activeCampaign?.campaignId,
          DatasourceId: selectedSource?.metaName,
          Environment: getEnvironment,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: { getOverwriteItems: overwriteItemResponse[] } };
      const response = res.data.getOverwriteItems;
      if (response) {
        setOverwrittenItems(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedSource) {
      fetchLatestData();
    }
  }, [selectedSource]);
  return [overwrittenItems];
};
