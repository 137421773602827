/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getInfo = /* GraphQL */ `query GetInfo {
  getInfo {
    PK
    Name
    __typename
  }
}
` as GeneratedQuery<APITypes.GetInfoQueryVariables, APITypes.GetInfoQuery>;
export const getDataSourcesDEPRECATED = /* GraphQL */ `query GetDataSourcesDEPRECATED($campaignId: String) {
  getDataSourcesDEPRECATED(campaignId: $campaignId) {
    source_name
    sources {
      name
      view
      metrics {
        name
        format
        decimals
        __typename
      }
      dimensions
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataSourcesDEPRECATEDQueryVariables,
  APITypes.GetDataSourcesDEPRECATEDQuery
>;
export const getDataSources = /* GraphQL */ `query GetDataSources($campaignId: String) {
  getDataSources(campaignId: $campaignId) {
    source_name
    metrics {
      metric
      description
      view
      __typename
    }
    dimensions {
      dimension
      description
      view
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataSourcesQueryVariables,
  APITypes.GetDataSourcesQuery
>;
export const getComments = /* GraphQL */ `query GetComments(
  $campaign: Int!
  $sub_campaign: Int!
  $media_source: mediaSource!
  $comment_type: commentType!
  $placeholder: String!
  $show_removed: Boolean
) {
  getComments(
    campaign: $campaign
    sub_campaign: $sub_campaign
    media_source: $media_source
    comment_type: $comment_type
    placeholder: $placeholder
    show_removed: $show_removed
  ) {
    campaign
    sub_campaign
    placeholder
    media_source
    comment_type
    comment
    created_at
    last_edited_at
    created_by
    last_edited_by
    state
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentsQueryVariables,
  APITypes.GetCommentsQuery
>;
export const getCampaignComments = /* GraphQL */ `query GetCampaignComments(
  $campaign: Int!
  $comment_type: commentType!
  $media_source: mediaSource!
  $placeholder: String
  $sub_campaign: Int
) {
  getCampaignComments(
    campaign: $campaign
    comment_type: $comment_type
    media_source: $media_source
    placeholder: $placeholder
    sub_campaign: $sub_campaign
  ) {
    campaign
    sub_campaign
    placeholder
    media_source
    comment_type
    comment
    created_at
    last_edited_at
    created_by
    last_edited_by
    state
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignCommentsQueryVariables,
  APITypes.GetCampaignCommentsQuery
>;
export const getClientList = /* GraphQL */ `query GetClientList($Organization_id: String!) {
  getClientList(Organization_id: $Organization_id) {
    OrganizationId
    Country
    ClientCodes
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClientListQueryVariables,
  APITypes.GetClientListQuery
>;
export const getClientListBeta = /* GraphQL */ `query GetClientListBeta {
  getClientListBeta {
    Organization
    OrganizationId
    Country
    ClientCodes
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClientListBetaQueryVariables,
  APITypes.GetClientListBetaQuery
>;
export const getCampaigns = /* GraphQL */ `query GetCampaigns(
  $clients: String!
  $country: country!
  $from: String
  $to: String
) {
  getCampaigns(clients: $clients, country: $country, from: $from, to: $to) {
    foundCampaigns {
      campaignId
      name
      __typename
    }
    notFoundCampaigns {
      client
      country
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignsQueryVariables,
  APITypes.GetCampaignsQuery
>;
export const getCampaignsV2 = /* GraphQL */ `query GetCampaignsV2(
  $clients: String!
  $country: country!
  $from: String
  $to: String
  $deleted: Boolean
  $desc: Boolean
  $Environment: Environment
) {
  getCampaignsV2(
    clients: $clients
    country: $country
    from: $from
    to: $to
    deleted: $deleted
    desc: $desc
    Environment: $Environment
  ) {
    PK
    SK
    campaignId
    campaignName
    clientCode
    clientCountry
    comments
    endDate
    startDate
    deleted
    additionalInfo
    additionalInfo2
    createdBy
    briefedBudget
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignsV2QueryVariables,
  APITypes.GetCampaignsV2Query
>;
export const getSubCampaigns = /* GraphQL */ `query GetSubCampaigns($campaignId: String!, $media: String) {
  getSubCampaigns(campaignId: $campaignId, media: $media) {
    subcampaignId
    name
    startDate
    endDate
    mediaGroup
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubCampaignsQueryVariables,
  APITypes.GetSubCampaignsQuery
>;
export const getCampaignInfo = /* GraphQL */ `query GetCampaignInfo($campaignIds: String!) {
  getCampaignInfo(campaignIds: $campaignIds) {
    foundCampaigns {
      startDate
      endDate
      createdBy
      comments
      additionalInfo
      additionalInfo2
      campaignId
      name
      clientCode {
        client
        country
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignInfoQueryVariables,
  APITypes.GetCampaignInfoQuery
>;
export const getQuery = /* GraphQL */ `query GetQuery($id: String) {
  getQuery(id: $id) {
    id
    status
    submitted
    outputlocation
    bytes_scanned
    user_name
    source
    bucket
    query
    error
    message
    __typename
  }
}
` as GeneratedQuery<APITypes.GetQueryQueryVariables, APITypes.GetQueryQuery>;
export const getQueries = /* GraphQL */ `query GetQueries($campaign_id: String!) {
  getQueries(campaign_id: $campaign_id) {
    campaign_id
    position
    query_id
    query_id_cc
    title
    metrics {
      chartType
      metric
      axis
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQueriesQueryVariables,
  APITypes.GetQueriesQuery
>;
export const getStoredQuery = /* GraphQL */ `query GetStoredQuery($campaign_id: String!, $position: String!) {
  getStoredQuery(campaign_id: $campaign_id, position: $position) {
    campaign_id
    position
    query_id
    query_id_cc
    title
    metrics {
      chartType
      metric
      axis
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStoredQueryQueryVariables,
  APITypes.GetStoredQueryQuery
>;
export const getPublishedOutput = /* GraphQL */ `query GetPublishedOutput(
  $clientCode: String!
  $clientCountry: String!
  $campaign_id: String!
  $dashboardType: DashboardTypes!
  $subCampaignId: String
  $position: String
) {
  getPublishedOutput(
    clientCode: $clientCode
    clientCountry: $clientCountry
    campaign_id: $campaign_id
    dashboardType: $dashboardType
    subCampaignId: $subCampaignId
    position: $position
  ) {
    campaign_id
    placeholders {
      position
      query_id
      query_id_cc
      outputlocation
      outputlocationTotal
      outputlocation_cc
      outputlocationTotal_cc
      title
      dashboardType
      publishedAt
      publishedBy
      metrics {
        chartType
        metric
        axis
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublishedOutputQueryVariables,
  APITypes.GetPublishedOutputQuery
>;
export const getPublishedCampaigns = /* GraphQL */ `query GetPublishedCampaigns(
  $campaigns: [String]!
  $dashboardType: DashboardTypes!
) {
  getPublishedCampaigns(campaigns: $campaigns, dashboardType: $dashboardType) {
    campaignId
    subCampaignId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublishedCampaignsQueryVariables,
  APITypes.GetPublishedCampaignsQuery
>;
export const getPublishedCampaigns_v2 = /* GraphQL */ `query GetPublishedCampaigns_v2($campaigns: [String]!) {
  getPublishedCampaigns_v2(campaigns: $campaigns) {
    campaignId
    subCampaignId
    published {
      TV
      RADIO
      PRINT
      OOH
      DIGITAL
      CINEMA
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublishedCampaigns_v2QueryVariables,
  APITypes.GetPublishedCampaigns_v2Query
>;
export const getUsers = /* GraphQL */ `query GetUsers(
  $Environment: Environment!
  $Organization_id: String!
  $UserType: UserType!
  $StatementType: StatementType!
) {
  getUsers(
    Environment: $Environment
    Organization_id: $Organization_id
    UserType: $UserType
    StatementType: $StatementType
  ) {
    Username
    Name
    Family_name
    Email
    HasFullAccess {
      ClientCode
      ClientCountry
      __typename
    }
    HasReadAccess {
      ClientCode
      ClientCountry
      __typename
    }
    HasNoAccess {
      ClientCode
      ClientCountry
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUsersQueryVariables, APITypes.GetUsersQuery>;
export const getUsersV2 = /* GraphQL */ `query GetUsersV2(
  $Environment: Environment!
  $Organization_id: String!
  $UserType: UserType!
  $StatementType: StatementType!
  $Limit: LimitType
) {
  getUsersV2(
    Environment: $Environment
    Organization_id: $Organization_id
    UserType: $UserType
    StatementType: $StatementType
    Limit: $Limit
  ) {
    Data {
      Username
      Name
      Family_name
      Email
      Access {
        Level
        Clients {
          ClientCode
          ClientCountry
          __typename
        }
        __typename
      }
      __typename
    }
    Meta {
      Clients {
        returned
        total
        start_index
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUsersV2QueryVariables,
  APITypes.GetUsersV2Query
>;
export const getUsersV3 = /* GraphQL */ `query GetUsersV3(
  $Environment: Environment!
  $Organization_id: String!
  $UserType: UserType!
  $StatementType: StatementType!
) {
  getUsersV3(
    Environment: $Environment
    Organization_id: $Organization_id
    UserType: $UserType
    StatementType: $StatementType
  ) {
    Username
    Name
    Family_name
    Email
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUsersV3QueryVariables,
  APITypes.GetUsersV3Query
>;
export const getUser = /* GraphQL */ `query GetUser(
  $Environment: Environment!
  $Organization_id: String!
  $StatementType: StatementType!
) {
  getUser(
    Environment: $Environment
    Organization_id: $Organization_id
    StatementType: $StatementType
  ) {
    Username
    Name
    Family_name
    Email
    HasFullAccess {
      ClientCode
      ClientCountry
      __typename
    }
    HasReadAccess {
      ClientCode
      ClientCountry
      __typename
    }
    HasNoAccess {
      ClientCode
      ClientCountry
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const getUserV2 = /* GraphQL */ `query GetUserV2(
  $Environment: Environment!
  $Organization_id: String!
  $StatementType: StatementType!
  $User: String
) {
  getUserV2(
    Environment: $Environment
    Organization_id: $Organization_id
    StatementType: $StatementType
    User: $User
  ) {
    Username
    Name
    Family_name
    Email
    Access {
      Level
      Clients {
        ClientCode
        ClientCountry
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserV2QueryVariables, APITypes.GetUserV2Query>;
export const getStatementsFromClient = /* GraphQL */ `query GetStatementsFromClient(
  $ClientCountry: String!
  $ClientCode: String!
  $Environment: Environment!
) {
  getStatementsFromClient(
    ClientCountry: $ClientCountry
    ClientCode: $ClientCode
    Environment: $Environment
  ) {
    Comment {
      READ
      WRITE
      __typename
    }
    Queries {
      READ
      CREATE
      PUBLISH
      UNPUBLISH
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatementsFromClientQueryVariables,
  APITypes.GetStatementsFromClientQuery
>;
export const userIsAuthorized = /* GraphQL */ `query UserIsAuthorized(
  $Clients: [ClientsInput]
  $Resource: String!
  $Environment: Environment!
  $Action: ActionEnum!
) {
  userIsAuthorized(
    Clients: $Clients
    Resource: $Resource
    Environment: $Environment
    Action: $Action
  ) {
    Client {
      ClientCode
      ClientCountry
      __typename
    }
    Authorized
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserIsAuthorizedQueryVariables,
  APITypes.UserIsAuthorizedQuery
>;
export const userIsAuthorizedMulti = /* GraphQL */ `query UserIsAuthorizedMulti(
  $Organization_id: String!
  $Resource: String!
  $Service: String!
  $StatementType: StatementType!
  $Environment: Environment!
  $Action: ActionEnum!
) {
  userIsAuthorizedMulti(
    Organization_id: $Organization_id
    Resource: $Resource
    Service: $Service
    StatementType: $StatementType
    Environment: $Environment
    Action: $Action
  ) {
    Client {
      ClientCode
      ClientCountry
      __typename
    }
    Authorized
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserIsAuthorizedMultiQueryVariables,
  APITypes.UserIsAuthorizedMultiQuery
>;
export const getAudienceReport = /* GraphQL */ `query GetAudienceReport($campaignId: String!) {
  getAudienceReport(campaignId: $campaignId) {
    campaignId
    outputlocation
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAudienceReportQueryVariables,
  APITypes.GetAudienceReportQuery
>;
export const getPortalDashboards = /* GraphQL */ `query GetPortalDashboards(
  $Organization_id: String!
  $Environment: Environment!
  $StatementType: StatementType!
) {
  getPortalDashboards(
    Organization_id: $Organization_id
    Environment: $Environment
    StatementType: $StatementType
  ) {
    activities {
      name
      dashboard
      url
      internal_launch
      external_launch
      accessible
      sortOrder
      color
      description
      __typename
    }
    insights {
      name
      dashboard
      url
      internal_launch
      external_launch
      accessible
      sortOrder
      color
      description
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPortalDashboardsQueryVariables,
  APITypes.GetPortalDashboardsQuery
>;
export const getConcerns = /* GraphQL */ `query GetConcerns($Environment: Environment!, $Country: country) {
  getConcerns(Environment: $Environment, Country: $Country) {
    Organization
    OrganizationId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConcernsQueryVariables,
  APITypes.GetConcernsQuery
>;
export const getPreSignedUrl = /* GraphQL */ `query GetPreSignedUrl(
  $ClientMethod: S3Method!
  $Client: ClientInput!
  $Environment: Environment!
  $FileName: String!
  $ContentType: String
  $Exp: Int
) {
  getPreSignedUrl(
    ClientMethod: $ClientMethod
    Client: $Client
    Environment: $Environment
    FileName: $FileName
    ContentType: $ContentType
    Exp: $Exp
  ) {
    status
    message
    url
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPreSignedUrlQueryVariables,
  APITypes.GetPreSignedUrlQuery
>;
export const getInsightsDashboards = /* GraphQL */ `query GetInsightsDashboards(
  $Environment: Environment!
  $Organization_id: String!
) {
  getInsightsDashboards(
    Environment: $Environment
    Organization_id: $Organization_id
  ) {
    ClientCode
    ClientCountry
    Resources {
      Dashboard
      Access
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInsightsDashboardsQueryVariables,
  APITypes.GetInsightsDashboardsQuery
>;
export const getInsightsAccess = /* GraphQL */ `query GetInsightsAccess(
  $Environment: Environment!
  $Organization_id: String!
  $UserType: UserType!
) {
  getInsightsAccess(
    Environment: $Environment
    Organization_id: $Organization_id
    UserType: $UserType
  ) {
    Users {
      Username
      Name
      Family_name
      Email
      Statements {
        Service
        Resource
        Action
        ClientCode
        ClientCountry
        Environment
        __typename
      }
      __typename
    }
    Clients {
      ClientCode
      ClientCountry
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInsightsAccessQueryVariables,
  APITypes.GetInsightsAccessQuery
>;
export const listTemplates = /* GraphQL */ `query ListTemplates(
  $ClientCode: String!
  $ClientCountry: String!
  $Environment: Environment!
  $CreatedBy: String
) {
  listTemplates(
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Environment: $Environment
    CreatedBy: $CreatedBy
  ) {
    Name
    Id
    CreatedBy
    CreatedAt
    LastEditedBy
    LastEditedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplatesQueryVariables,
  APITypes.ListTemplatesQuery
>;
export const listTemplates_v2 = /* GraphQL */ `query ListTemplates_v2(
  $Organization_id: String!
  $Environment: Environment!
  $Client: ClientCode
  $CreatedBy: String
) {
  listTemplates_v2(
    Organization_id: $Organization_id
    Environment: $Environment
    Client: $Client
    CreatedBy: $CreatedBy
  ) {
    Name
    Id
    CreatedBy
    CreatedAt
    LastEditedBy
    LastEditedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplates_v2QueryVariables,
  APITypes.ListTemplates_v2Query
>;
export const getTemplateById = /* GraphQL */ `query GetTemplateById($TemplateId: String!, $Client: ClientsInput) {
  getTemplateById(TemplateId: $TemplateId, Client: $Client) {
    Name
    Id
    CreatedBy
    CreatedAt
    LastEditedBy
    LastEditedAt
    Placeholders {
      Placeholder
      Placeholder_titel
      S3Location
      S3Location_cc
      OrderIndex
      Metrics {
        chartType
        metric
        axis
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTemplateByIdQueryVariables,
  APITypes.GetTemplateByIdQuery
>;
export const getOrganizationCampaigns = /* GraphQL */ `query GetOrganizationCampaigns(
  $Organization_id: String!
  $from: String
  $to: String
) {
  getOrganizationCampaigns(
    Organization_id: $Organization_id
    from: $from
    to: $to
  ) {
    foundCampaigns {
      campaign
      campaignId
      ClientCode
      ClientCountry
      ClientName
      __typename
    }
    notFoundCampaigns {
      campaign
      campaignId
      ClientCode
      ClientCountry
      ClientName
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationCampaignsQueryVariables,
  APITypes.GetOrganizationCampaignsQuery
>;
export const getPublishedCampaign = /* GraphQL */ `query GetPublishedCampaign(
  $campaign_id: String!
  $dashboardType: DashboardTypes
) {
  getPublishedCampaign(
    campaign_id: $campaign_id
    dashboardType: $dashboardType
  ) {
    campaignId
    subCampaignId
    dashboardType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublishedCampaignQueryVariables,
  APITypes.GetPublishedCampaignQuery
>;
export const getCustomValue = /* GraphQL */ `query GetCustomValue(
  $campaignId: Int!
  $dashboardType: String!
  $position: String!
) {
  getCustomValue(
    campaignId: $campaignId
    dashboardType: $dashboardType
    position: $position
  ) {
    campaignId
    label
    dashboardType
    position
    floatValue
    status
    createdBy
    createdAt
    format
    subtitle
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomValueQueryVariables,
  APITypes.GetCustomValueQuery
>;
export const getCustomValueHistory = /* GraphQL */ `query GetCustomValueHistory(
  $campaignId: Int!
  $dashboardType: mediaSource!
  $position: String!
) {
  getCustomValueHistory(
    campaignId: $campaignId
    dashboardType: $dashboardType
    position: $position
  ) {
    campaignId
    label
    dashboardType
    position
    floatValue
    status
    createdBy
    createdAt
    format
    subtitle
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomValueHistoryQueryVariables,
  APITypes.GetCustomValueHistoryQuery
>;
export const getFlowchartCampaignsPerClient = /* GraphQL */ `query GetFlowchartCampaignsPerClient(
  $clientCode: String!
  $clientCountry: String!
  $from: AWSDate!
) {
  getFlowchartCampaignsPerClient(
    clientCode: $clientCode
    clientCountry: $clientCountry
    from: $from
  ) {
    campaignId
    campaign
    startDate
    endDate
    ClientCode
    ClientCountry
    ClientName
    status
    createdBy
    createdAt
    briefed_amount
    briefed_currency
    briefed_type
    briefed_vat_info
    additionalInfo
    additionalInfo2
    comment
    tagIds
    recommended_budget_ctc
    approved_budget
    actual_fee
    planned_fee
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlowchartCampaignsPerClientQueryVariables,
  APITypes.GetFlowchartCampaignsPerClientQuery
>;
export const getFlowchartCampaigns = /* GraphQL */ `query GetFlowchartCampaigns($Organization_id: String!, $from: AWSDate!) {
  getFlowchartCampaigns(Organization_id: $Organization_id, from: $from) {
    campaignId
    campaign
    startDate
    endDate
    ClientCode
    ClientCountry
    ClientName
    status
    dashboardType
    createdBy
    createdAt
    briefed_amount
    briefed_currency
    briefed_type
    briefed_vat_info
    additionalInfo
    additionalInfo2
    comment
    budget
    tagIds
    recommended_budget_ctc
    approved_budget
    actual_fee
    planned_fee
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlowchartCampaignsQueryVariables,
  APITypes.GetFlowchartCampaignsQuery
>;
export const getFlowchart = /* GraphQL */ `query GetFlowchart($Organization_id: String!, $endDate: AWSDate!) {
  getFlowchart(Organization_id: $Organization_id, endDate: $endDate) {
    campaignId
    campaign
    startDate
    endDate
    ClientCode
    ClientCountry
    ClientName
    status
    createdBy
    createdAt
    briefed_amount
    briefed_currency
    briefed_type
    briefed_vat_info
    additionalInfo
    additionalInfo2
    comment
    tagIds
    recommended_budget_ctc
    approved_budget
    actual_fee
    planned_fee
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlowchartQueryVariables,
  APITypes.GetFlowchartQuery
>;
export const getOverviewStatus = /* GraphQL */ `query GetOverviewStatus(
  $source: String
  $client: String
  $campaignId: String
  $Resolved: Boolean
) {
  getOverviewStatus(
    source: $source
    client: $client
    campaignId: $campaignId
    Resolved: $Resolved
  ) {
    source
    severity
    status
    message
    client
    campaignId
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOverviewStatusQueryVariables,
  APITypes.GetOverviewStatusQuery
>;
export const getCampaignType = /* GraphQL */ `query GetCampaignType($client: String!, $country: String!, $from: AWSDate!) {
  getCampaignType(client: $client, country: $country, from: $from) {
    campaignId
    datasource
    client
    name
    type
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignTypeQueryVariables,
  APITypes.GetCampaignTypeQuery
>;
export const getBriefTemplate = /* GraphQL */ `query GetBriefTemplate(
  $Environment: Environment!
  $OrganizationId: String!
  $Version: Int
) {
  getBriefTemplate(
    Environment: $Environment
    OrganizationId: $OrganizationId
    Version: $Version
  ) {
    template
    templateId
    version
    createdAt
    createdBy
    sections {
      sectionName
      columns {
        name
        label
        description
        type
        id
        required
        placeholder
        valueType
        value {
          StringValue
          IntValue
          BooleanValue
          FloatValue
          __typename
        }
        subColumns {
          name
          label
          description
          id
          type
          required
          valueType
          value {
            StringValue
            IntValue
            BooleanValue
            FloatValue
            __typename
          }
          placeholder
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBriefTemplateQueryVariables,
  APITypes.GetBriefTemplateQuery
>;
export const getClientTags = /* GraphQL */ `query GetClientTags(
  $ClientCode: String!
  $ClientCountry: String!
  $Environment: Environment!
) {
  getClientTags(
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Environment: $Environment
  ) {
    tagDescription
    tagGroup
    tagId
    tagName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClientTagsQueryVariables,
  APITypes.GetClientTagsQuery
>;
export const getTags = /* GraphQL */ `query GetTags($Organization_id: String!, $Environment: Environment!) {
  getTags(Organization_id: $Organization_id, Environment: $Environment) {
    Client
    Country
    Tags {
      tagDescription
      tagGroup
      tagId
      tagName
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagsQueryVariables, APITypes.GetTagsQuery>;
export const getCampaignTags = /* GraphQL */ `query GetCampaignTags($campaignId: Int!, $Environment: Environment!) {
  getCampaignTags(campaignId: $campaignId, Environment: $Environment) {
    tagDescription
    tagGroup
    tagId
    tagName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignTagsQueryVariables,
  APITypes.GetCampaignTagsQuery
>;
export const getBrief = /* GraphQL */ `query GetBrief(
  $CampaignId: Int!
  $Environment: Environment!
  $BriefSection: BriefSection
) {
  getBrief(
    CampaignId: $CampaignId
    Environment: $Environment
    BriefSection: $BriefSection
  ) {
    id
    createdAt
    createdBy
    type
    valueType
    sectionName
    label
    stringValue
    intValue
    booleanValue
    floatValue
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBriefQueryVariables, APITypes.GetBriefQuery>;
export const getBrief_v2 = /* GraphQL */ `query GetBrief_v2(
  $CampaignId: Int!
  $OrganizationId: String!
  $Environment: Environment!
) {
  getBrief_v2(
    CampaignId: $CampaignId
    OrganizationId: $OrganizationId
    Environment: $Environment
  ) {
    template
    templateId
    version
    createdAt
    createdBy
    sections {
      sectionName
      columns {
        name
        label
        description
        type
        id
        required
        placeholder
        valueType
        value {
          StringValue
          IntValue
          BooleanValue
          FloatValue
          __typename
        }
        subColumns {
          name
          label
          description
          id
          type
          required
          valueType
          value {
            StringValue
            IntValue
            BooleanValue
            FloatValue
            __typename
          }
          placeholder
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrief_v2QueryVariables,
  APITypes.GetBrief_v2Query
>;
export const getFlowchartCampaignsPerClient_v2 = /* GraphQL */ `query GetFlowchartCampaignsPerClient_v2(
  $clientCode: String!
  $clientCountry: String!
  $from: AWSDate!
  $deleted: Boolean
) {
  getFlowchartCampaignsPerClient_v2(
    clientCode: $clientCode
    clientCountry: $clientCountry
    from: $from
    deleted: $deleted
  ) {
    campaignId
    campaign
    startDate
    endDate
    ClientCode
    ClientCountry
    ClientName
    status
    createdBy
    createdAt
    briefed_amount
    briefed_currency
    briefed_type
    briefed_vat_info
    additionalInfo
    additionalInfo2
    comment
    tagIds
    recommended_budget_ctc
    approved_budget
    actual_fee
    planned_fee
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlowchartCampaignsPerClient_v2QueryVariables,
  APITypes.GetFlowchartCampaignsPerClient_v2Query
>;
export const getDetailedPlanning = /* GraphQL */ `query GetDetailedPlanning(
  $CampaignId: Int!
  $OrganizationId: String!
  $Environment: Environment!
) {
  getDetailedPlanning(
    CampaignId: $CampaignId
    OrganizationId: $OrganizationId
    Environment: $Environment
  ) {
    templateId
    templateName
    columns {
      name
      id
      type
      valueType
      __typename
    }
    rows {
      section
      values {
        rowId
        columns {
          columnId
          valueType
          value {
            StringValue
            IntValue
            BooleanValue
            FloatValue
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDetailedPlanningQueryVariables,
  APITypes.GetDetailedPlanningQuery
>;
export const getDetailedPlanningRow = /* GraphQL */ `query GetDetailedPlanningRow(
  $CampaignId: Int!
  $Environment: Environment!
  $RowId: String!
) {
  getDetailedPlanningRow(
    CampaignId: $CampaignId
    Environment: $Environment
    RowId: $RowId
  ) {
    section
    values {
      rowId
      columns {
        columnId
        valueType
        value {
          StringValue
          IntValue
          BooleanValue
          FloatValue
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDetailedPlanningRowQueryVariables,
  APITypes.GetDetailedPlanningRowQuery
>;
export const getStoredQueryOrder = /* GraphQL */ `query GetStoredQueryOrder(
  $CampaignId: Int!
  $DashboardType: mediaSource!
  $DashboardSection: DashboardSection!
) {
  getStoredQueryOrder(
    CampaignId: $CampaignId
    DashboardType: $DashboardType
    DashboardSection: $DashboardSection
  ) {
    QueryId
    Order
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStoredQueryOrderQueryVariables,
  APITypes.GetStoredQueryOrderQuery
>;
export const getAllLinksByOrg = /* GraphQL */ `query GetAllLinksByOrg($Organization_id: String!, $Environment: Environment!) {
  getAllLinksByOrg(
    Organization_id: $Organization_id
    Environment: $Environment
  ) {
    order
    links {
      Id
      title
      linkValue
      icon
      image
      clientCodes {
        ClientCode
        ClientCountry
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllLinksByOrgQueryVariables,
  APITypes.GetAllLinksByOrgQuery
>;
export const getCustomLink = /* GraphQL */ `query GetCustomLink(
  $ItemId: String!
  $sortKey: String
  $OrganizationId: String!
  $Environment: Environment!
) {
  getCustomLink(
    ItemId: $ItemId
    sortKey: $sortKey
    OrganizationId: $OrganizationId
    Environment: $Environment
  ) {
    Id
    title
    linkValue
    icon
    image
    clientCodes {
      ClientCode
      ClientCountry
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomLinkQueryVariables,
  APITypes.GetCustomLinkQuery
>;
export const getCustomLinksBatch = /* GraphQL */ `query GetCustomLinksBatch(
  $ItemId: String!
  $OrganizationId: String!
  $Environment: Environment!
) {
  getCustomLinksBatch(
    ItemId: $ItemId
    OrganizationId: $OrganizationId
    Environment: $Environment
  ) {
    Id
    title
    linkValue
    icon
    image
    clientCodes {
      ClientCode
      ClientCountry
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomLinksBatchQueryVariables,
  APITypes.GetCustomLinksBatchQuery
>;
export const getLatestOverwrite = /* GraphQL */ `query GetLatestOverwrite(
  $ItemId: String!
  $DatasourceId: String!
  $Environment: Environment
) {
  getLatestOverwrite(
    ItemId: $ItemId
    DatasourceId: $DatasourceId
    Environment: $Environment
  ) {
    ItemId
    ItemName
    NewValue
    Reason
    Metric
    SourceName
    Timestamp
    RequestedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLatestOverwriteQueryVariables,
  APITypes.GetLatestOverwriteQuery
>;
export const getOverwriteItems = /* GraphQL */ `query GetOverwriteItems(
  $CampaignId: Int!
  $DatasourceId: String!
  $Environment: Environment
) {
  getOverwriteItems(
    CampaignId: $CampaignId
    DatasourceId: $DatasourceId
    Environment: $Environment
  ) {
    ItemId
    ItemName
    NewValue
    Reason
    Metric
    SourceName
    Timestamp
    RequestedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOverwriteItemsQueryVariables,
  APITypes.GetOverwriteItemsQuery
>;
export const getProjectLevel = /* GraphQL */ `query GetProjectLevel($Clients: [ClientsInput]!, $Environment: Environment!) {
  getProjectLevel(Clients: $Clients, Environment: $Environment) {
    ClientCode
    ClientCountry
    Level
    Status
    Updated_at
    Updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectLevelQueryVariables,
  APITypes.GetProjectLevelQuery
>;
