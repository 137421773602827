import {
  DashboardTypes,
  GetCustomValueHistoryQuery,
  GetCustomValueQuery,
  SaveCustomValueMutation,
  mediaSource,
} from 'API';
import { API } from 'aws-amplify';
import { useCampaignContext } from 'context/CampaignContext';
import { CommentsContext } from 'context/CommentsContext';
import { EnrichedContext } from 'context/EnrichedContext';
import { PublishContext } from 'context/PublishContext';
import { saveCustomValue } from 'graphql/mutations';
import { getCustomValueHistory } from 'graphql/queries';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { DarkButton, IconButton } from './style/Global';
import { Modal } from './NewPortal/Header/AddCampaignModal';
import { HeadModul, ModulContainer } from './Main/table/TableStyles';
import { IoMdClose } from 'react-icons/io';
import { iconList } from 'assets/newAssets/SVG/additionalIconsForLinks/iconList';

type Props = {
  componentID: string;
  location: string;
  getTitleFromAPI?: (title: string, subtitle: string, format: string) => void;
  setIsKPIeditable?: (arg: boolean) => void;
};

export const KPItoS3 = (props: Props) => {
  const { activeCampaign, dashType } = useCampaignContext();
  const { KPIValues } = useContext(CommentsContext);
  const { updateOLArray, publishedData } = useContext(PublishContext);

  const [labelValue, setLabelValue] = useState('Custom value');
  const [subtitle, setSubtitle] = useState('Custom value');
  const [format, setformat] = useState('number');
  const formatoptions = ['number', 'percentage'];

  const [val, setVal] = useState<number | null>(null);
  const [edit, setEdit] = useState(false);
  const handleSave = () => {
    val === 0 ? setVal(null) : setVal(val);
    labelValue === '' ? setLabelValue('Custom value') : setLabelValue(labelValue);
    subtitle === '' ? setSubtitle('Custom value') : setSubtitle(subtitle);
    format === '' ? setformat('number') : setformat(format);
    CustomValueSave(val);
    // setEdit(!edit)
  };

  useEffect(() => {
    if (activeCampaign?.campaignId && activeCampaign.authorized) GetCustomValue();
  }, [activeCampaign]);

  useEffect(() => {
    props.setIsKPIeditable && props.setIsKPIeditable(edit);
  }, [edit]);

  useEffect(() => {
    if (publishedData && !activeCampaign?.authorized) {
      publishedData.forEach((e) => {
        if (e.position === props.componentID) {
          setVal(e.outputlocation);
          setLabelValue(e.title);
          setSubtitle(e.outputlocation_cc);
          setformat(e.outputlocationTotal_cc);

          props.getTitleFromAPI &&
            props.getTitleFromAPI(
              e.title ? (e.title as string) : '',
              e.outputlocation_cc !== null ? (e.outputlocation_cc as string) : '',
              e.outputlocationTotal_cc ? (e.outputlocationTotal_cc as string) : '',
            );
        }
      });
    }
  }, [publishedData]);

  const theme = useTheme();
  async function CustomValueSave(value: any) {
    const vars =
      props.location === 'summary'
        ? {
            CustomValue: {
              campaignId: activeCampaign?.campaignId,
              dashboardType: 'OVERALL',
              position: props.componentID,
              floatValue: value === 0 ? null : value,
              label: labelValue === '' ? 'Custom value' : labelValue,
            },
          }
        : props.location === 'Cinema' || props.location === 'Print' || props.location === 'OOH'
        ? {
            CustomValue: {
              campaignId: activeCampaign?.campaignId,
              dashboardType: props.location.toUpperCase(),
              position: props.componentID,
              floatValue: value === 0 ? null : value,
              label: labelValue === '' ? 'Custom value' : labelValue,
              subtitle: subtitle === '' ? 'Custom value' : subtitle,
              format: format === '' ? 'number' : format,
            },
          }
        : {
            CustomValue: {
              campaignId: activeCampaign?.campaignId,
              dashboardType:
                mediaSource[
                  dashType === DashboardTypes.HOME || dashType === DashboardTypes.FLOWCHART
                    ? 'OVERALL'
                    : (dashType as keyof typeof mediaSource)
                ],
              position: props.componentID,
              floatValue: value === 0 ? null : value,
            },
          };

    try {
      const response = (await API.graphql({
        query: saveCustomValue,
        variables: vars,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: SaveCustomValueMutation };
      const placeholdersObject = {
        position: props.componentID,
        outputlocation: value,
        title: labelValue === '' ? 'Custom value' : labelValue,
        outputlocation_cc: subtitle === '' ? 'Custom value' : subtitle,
        outputlocationTotal_cc: format === '' ? 'number' : format,
      };

      updateOLArray(placeholdersObject);
      props.getTitleFromAPI && props.getTitleFromAPI(labelValue, subtitle, format);
      setEdit(!edit);
    } catch (e) {
      console.log('ERROR SAVING CUSTOM VALUE TEMPLATE', e);
      setVal(null);
    }
  }

  async function GetCustomValue() {
    try {
      const response = (await API.graphql({
        query: getCustomValueHistory,
        variables: {
          campaignId: activeCampaign?.campaignId,
          dashboardType:
            mediaSource[
              dashType === DashboardTypes.HOME || dashType === DashboardTypes.FLOWCHART
                ? 'OVERALL'
                : (dashType as keyof typeof mediaSource)
            ],
          position: props.componentID,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetCustomValueHistoryQuery };

      const resArray = response.data.getCustomValueHistory;

      // props.getTitleFromAPI && props.getTitleFromAPI("SSSSS")

      if (resArray !== null && resArray !== undefined) {
        //OVO ZNACI DA JE NESTO BILO SPREMLJENO I DA POSTOJI NEKA VRIJEDNOST TAKO DA MOGU PROVJERITI DA LI IMA NEKOG TITLEA
        // AKO TITLE NE POSTOJI A POSTOJI VRIJEDNOST ONDA CE MI TITLE BITI PERSOONS
        if (resArray.some((e) => e?.position === props.componentID && e?.status === 'ACTIVE')) {
          // in resArray, find the one with the latest created_at date
          let latestDate = new Date(0);
          let latestComment = resArray[0];
          resArray?.forEach((e) => {
            if (new Date(e?.createdAt as string) > latestDate) {
              latestDate = new Date(e?.createdAt as string);
              latestComment = e;
            }
          });
          if (latestComment) {
            setVal(latestComment.floatValue === null ? null : (latestComment.floatValue as number));
            const placeholdersObject = {
              position: props.componentID,
              outputlocation: latestComment?.floatValue,
              title: latestComment.label !== null ? (latestComment.label as string) : '',
              outputlocation_cc: latestComment.subtitle !== null ? (latestComment.subtitle as string) : '',
              outputlocationTotal_cc: latestComment.format !== null ? (latestComment.format as string) : '',
            };
            setLabelValue(latestComment.label === null ? 'Custom value' : (latestComment.label as string));
            setSubtitle(latestComment.subtitle === null ? 'Custom value' : (latestComment.subtitle as string));
            setformat(latestComment.format === null ? 'Custom value' : (latestComment.format as string));
            props.getTitleFromAPI &&
              props.getTitleFromAPI(
                latestComment.label !== null ? (latestComment.label as string) : '',
                latestComment.subtitle !== null ? (latestComment.subtitle as string) : '',
                latestComment.format !== null ? (latestComment.format as string) : '',
              );
            updateOLArray(placeholdersObject);
          }
        } else {
          // AKO NEMA NISTA SPREMLJENO ONDA CE MI TITLE BITI CUSTOM VALUE
          props.getTitleFromAPI && props.getTitleFromAPI('Custom value', 'Custom value', 'number');
          // readOldFromComment()
        }
      } else {
        // AKO NEMA NISTA SPREMLJENO ONDA CE MI TITLE BITI CUSTOM VALUE
        props.getTitleFromAPI && props.getTitleFromAPI('Custom value', 'Custom value', 'number');
        // readOldFromComment()
      }
    } catch (e) {
      console.log('ERROR GET CUSTOM VALUEE', e);
    }
  }

  // console.log("LOCATION", props.location, props.componentID)

  const editIcon = iconList.find((e) => e === 'edit');
  return (
    <>
      {activeCampaign?.authorized && (
        <div
          onClick={() => {
            activeCampaign?.authorized && setEdit(!edit);
          }}
          className="testtt"
          style={{ position: 'absolute', top: 0, right: 0 }}
        >
          <button className="icon material-symbols-outlined">{editIcon}</button>
        </div>
      )}

      {props.location === 'summary' /* !edit && */ && (
        <h1
          onClick={() => {
            activeCampaign?.authorized && setEdit(!edit);
          }}
          style={{ fontSize: '0.6vw' }}
        >
          {labelValue}
        </h1>
      )}

      <div className="valueFlex">
        {/* {!edit && */}
        <h4
          onClick={() => {
            activeCampaign?.authorized && setEdit(!edit);
          }}
        >
          {val === null
            ? 'NaN'
            : Intl.NumberFormat('en-DK', { notation: 'compact', maximumFractionDigits: 1 }).format(Number(val))}
          {props.componentID.includes('2') &&
            dashType !== 'CINEMA' &&
            dashType !== 'PRINT' &&
            dashType !== 'OOH' &&
            '%'}
          {(dashType === 'CINEMA' || dashType === 'PRINT' || dashType === 'OOH') && format === 'percentage' && '%'}
        </h4>
        {/* } */}

        {/* {edit && <EditDataModal setEdit={setEdit}></EditDataModal>} */}
      </div>
      {edit && (
        <Modal>
          <HeadModul>
            <button type="button" className="x" onClick={() => setEdit(false)}>
              {' '}
              <IoMdClose className="closeIcon" />
            </button>
            <ModulContainer>
              <h1>Edit Data</h1>
              <div className="editDataForm">
                {(props.location === 'summary' ||
                  props.location === 'Cinema' ||
                  props.location === 'Print' ||
                  props.location === 'OOH') && (
                  <>
                    <h1>
                      Label Title
                      <input defaultValue={labelValue} onChange={(e) => setLabelValue(e.target.value)} type="text" />
                    </h1>
                    {(props.location === 'Cinema' || props.location === 'Print' || props.location === 'OOH') && (
                      <h1>
                        Label Subtitle
                        <input defaultValue={labelValue} onChange={(e) => setSubtitle(e.target.value)} type="text" />
                      </h1>
                    )}
                    {(props.location === 'Cinema' || props.location === 'Print' || props.location === 'OOH') && (
                      <h1>
                        Value Format
                        <select defaultValue={labelValue} onChange={(e) => setformat(e.target.value)}>
                          {formatoptions.map((e) => (
                            <option value={e}>{e}</option>
                          ))}
                        </select>
                      </h1>
                    )}
                  </>
                )}

                <h1>
                  Value
                  <input
                    defaultValue={val === null ? 'NaN' : val}
                    type="number"
                    onChange={(e) => setVal(Number(e.target.value))}
                  />{' '}
                </h1>
              </div>

              <div style={{ display: 'flex', marginTop: '10px', gap: '10px', marginLeft: 'auto', marginRight: '10px' }}>
                <IconButton type="button" onClick={handleSave}>
                  Save
                </IconButton>
                <IconButton type="button" onClick={() => setEdit(false)}>
                  Cancel
                </IconButton>
              </div>
            </ModulContainer>
          </HeadModul>
        </Modal>
      )}
    </>
  );
};

// type ModalProps = {
//     setEdit: (arg: boolean) => void
// }

// export const EditDataModal = (props: ModalProps) => {
//     const { setEdit } = props

//     return (
//         <Modal>
//             <HeadModul>
//                 <button type='button' className="x" onClick={() => setEdit(false)}> <IoMdClose className='closeIcon' /></button>
//                 <ModulContainer>
//                     <h1>Edit Data</h1>

//                 </ModulContainer>
//             </HeadModul>
//         </Modal>
//     )

// }
