import React, { useContext, useState } from 'react';
import { OverwriteMainContainer, ResultsContainer, StatusToast } from './OverwriteMain.Styles';
import { DarkButton } from 'components/style/Global';
import { API } from 'aws-amplify';
import { createOverwriteData } from 'graphql/mutations';
import {
  CreateOverwriteDataMutation,
  CreateOverwriteDataMutationVariables,
  overwriteItem,
  overwriteItemResponse,
} from 'API';
import { useCampaignContext } from 'context/CampaignContext';
import APIContext from 'context/APIContext';
import { placeholderFullSources } from '../PlaceholderContainer';
// import { OverwriteMainContainer, ResultsContainer } from './StyledComponents'; // Adjust the import paths as necessary
// import Comments from './Comments'; // Adjust the import paths as necessary

interface Props {
  setUserMainQuery: any;
  mainData: any;
  selectedDataSource: placeholderFullSources | undefined;
  latestChangesArray: string;
  overwrittenItems: overwriteItemResponse[] | undefined;
  // setUserMainQuery: () => void;
  // mainData: { [key: string]: any }[];
}

const ResultPage: React.FC<Props> = (props) => {
  const { setUserMainQuery, mainData, selectedDataSource } = props;
  const { activeCampaign } = useCampaignContext();
  const { getEnvironment } = useContext(APIContext);
  const [showToast, setShowToast] = useState(false);

  // map mainData and in each object add a new key value pair which is a copy of the pair that has a numerical value and name it new + key
  const [dataWithNew, setDataWithNew] = useState<any[]>(() =>
    mainData.map((item: { [key: string]: any }) => {
      const newItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === 'number' && !key.startsWith('source')) {
          newItem[`New_${key}`] = item[key];
          //   newItem[`New_${key}`] = null;
        }
      });
      newItem.reason = '';
      return newItem;
    }),
  );

  const headers = dataWithNew.length > 0 ? Object.keys(dataWithNew[0]) : [];
  // Calculate sums for numeric columns
  const sums = headers.reduce((acc, header) => {
    if (header !== 'dimensionProperty' && header !== 'New_dimensionProperty') {
      acc[header] = dataWithNew.reduce((sum: number, item: any) => sum + (item[header] || 0), 0);
    }
    return acc;
  }, {} as { [key: string]: number });

  //   let reason = '';
  const [reason, setReason] = useState('');
  //   console.log('sums', sums);
  const handleSaveButton = async () => {
    const overwriteItemsArray: overwriteItem[] = dataWithNew
      .filter((item) => item.wasChanged)
      .map((item) => {
        const overwriteItem: overwriteItem = {
          ItemId: selectedDataSource?.metaName === 'DATASOURCE#CM' ? item.placement_id : item.lineitemid,
          ItemName: selectedDataSource?.metaName === 'DATASOURCE#CM' ? item.placement : item.lineitem,
          NewValue: item.New_impressions,
          Reason: reason,
          Metric: 'impressions',
          SourceName: selectedDataSource?.metaName,
        };
        return overwriteItem;
      });
    try {
      const response = (await API.graphql({
        query: createOverwriteData,
        variables: {
          Datasource_id: selectedDataSource?.metaName,
          CampaignId: activeCampaign?.campaignId,
          Environment: getEnvironment,
          OverwriteItems: overwriteItemsArray,
          //   setReason,
        } as CreateOverwriteDataMutationVariables,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: CreateOverwriteDataMutation };
      console.log(response);
      if (response.data.createOverwriteData) {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <OverwriteMainContainer>
      <ResultsContainer>
        <div className="table">
          {mainData && (
            <table>
              <thead>
                <tr>
                  {headers
                    .filter((h) => h !== 'wasChanged')
                    .map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {dataWithNew.map((item: { [key: string]: any }, index: number) => (
                  <tr key={index}>
                    {headers.map((header) => (
                      <td key={header}>
                        {header.startsWith('New') ? (
                          <input
                            type="number"
                            value={item[header]}
                            onChange={(e) => {
                              const value = e.target.value;
                              const newItem = {
                                ...item,
                                [header]: value === '' ? null : Number(value),
                                wasChanged: Number(value) !== item.tmp_impressions,
                              };
                              const newData = [...dataWithNew];
                              newData[index] = newItem;
                              setDataWithNew(newData);
                            }}
                          />
                        ) : header === 'reason' ? (
                          //   item.wasChanged && reason
                          item.wasChanged ? (
                            reason
                          ) : (
                            props.overwrittenItems?.find((i) => i.ItemId === item.placement_id)?.Reason
                          )
                        ) : (
                          //   <input
                          //     type="text"
                          //     value={item[header]}
                          //     onChange={(e) => {
                          //       const value = e.target.value;
                          //       const newItem = { ...item, [header]: value };
                          //       const newData = [...dataWithNew];
                          //       newData[index] = newItem;
                          //       setDataWithNew(newData);
                          //     }}
                          //   />
                          item[header]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  {headers.map((header) => (
                    <td key={header}>
                      {dataWithNew.filter((item) => typeof item[header] === 'number').length > 0 ? sums[header] : ''}
                    </td>
                  ))}
                </tr>
              </tfoot>
            </table>
          )}
        </div>
        <div className="comment">
          <textarea
            // defaultValue={reason}
            onInput={(e) => {
              //   reason = (e.target as HTMLInputElement).value;
              setReason((e.target as HTMLInputElement).value);
            }}
            placeholder="Eneter your reasoning here..."
          ></textarea>
        </div>
        <div className="actionButtons">
          <button className="backButton" type="button" onClick={() => setUserMainQuery()}>
            GO BACK
          </button>
          <DarkButton onClick={() => handleSaveButton()}>SAVE</DarkButton>
        </div>
      </ResultsContainer>
      {showToast && (
        <StatusToast>
          <p>Changes saved successfully</p>
        </StatusToast>
      )}
    </OverwriteMainContainer>
  );
};

export default ResultPage;
