/* import { LoginComponent } from "components/Header/LoginComponent"; */

import {
  DashboardTypes,
  DeleteStoredQueryMutation,
  GetPublishedOutputQuery,
  Placeholder,
  SwitchUserModeMutation,
  UserMode,
} from 'API';
import { API, button } from 'aws-amplify';
import Comments from 'components/Comments/Comments';
import { Selections } from 'components/Main/table/TableStyles';
import AddCampaignModal from 'components/NewPortal/Header/AddCampaignModal';
import EditCampaignModal from 'components/NewPortal/Header/EditCampaignModal';
import PickTemplate from 'components/Template/pickTemplate';
import SaveTemplate from 'components/Template/saveTemplate';
import { DarkButton, IconButton } from 'components/style/Global';
import APIContext from 'context/APIContext';
import { useCampaignContext } from 'context/CampaignContext';
import { useClientContext } from 'context/ClientContext';
import ClientStatementsContext from 'context/ClientStatementsContext';
import { CommentsContext } from 'context/CommentsContext';
import InputContext from 'context/InputContext';
import { usePortalContext } from 'context/PortalContext';
import { PublishContext } from 'context/PublishContext';
import { deleteStoredQuery } from 'graphql/mutations';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { TbBookDownload, TbBookUpload } from 'react-icons/tb';
import { VscComment } from 'react-icons/vsc';
import { ReactComponent as SVG_FILTER } from '../../assets/newAssets/filter.svg';
import { CampaignSelectComponent, CustomSelect } from './CampaignSelectComponent';
import {
  ClientsContainer,
  Container,
  Content,
  FilterContainer,
  Filtering,
  IconButtonContainer,
  StyledHeader,
} from './Header.styled';
import { useTemplateContext } from 'context/TemplateContext';
import { useGetSummaryStatus } from 'customHooks/useGettSummaryStatus';
import { set } from 'lodash';
import { getPublishedOutput } from 'graphql/queries';
import DeleteConfirmationModal from 'components/DeleteConfirmationModall';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const Header = (props: any) => {
  const {
    reducedClientList,
    activeCampaign,
    dashType,
    setTVStationSelector,
    setRadioStationSelector,
    clientFilterList,
    filterCampaignsPerClientCode,
    setClientFilterList,
    endDateFilter,
    startDateFilter,
    setStartDateFilter,
    setEndDateFilter,
    setOrder,
    order,
    permanentCampaignList,
    activeSubCampaign,
    canDoInsourceAndOnInsourcePortal,
  } = useCampaignContext();
  const { activeOrganization, loggedInUserAccessType, userCanDoInsource } = useClientContext();
  const [toggleComment, setToggleComment] = useState(false);
  const { commentsList } = useContext(CommentsContext);
  const { publishCampaign, unpublishCampaign, isPublished, placeholders } = useContext(PublishContext);
  const { statements } = useContext(ClientStatementsContext);
  const { getEnvironment } = useContext(APIContext);
  const { TVKPI, RADIOKPI, PRINTKPI, OOHKPI, networkList } = useContext(InputContext);
  const [pickTemplateDisplay, setPickTemplateDisplay] = useState(false);
  const [saveTemplateDisplay, setSaveTemplateDisplay] = useState(false);
  const [error, setError] = useState('warning');
  const [seeModal, setSeeModal] = useState(false);
  const { editCampaign } = usePortalContext();
  const [publishedData, setPublishedData] = useState<Placeholder[]>();

  // const placeholdersLength = (dashType === "RADIO" || dashType === "TV") ? 8 : (dashType === "PRINT" || dashType === "OOH" || dashType === "CINEMA") ? 2 : 0;
  // const isPublishButtonDisabled = placeholders ? placeholders[dashType as keyof typeof placeholders]?.length !== placeholdersLength ? true : false : true

  const isPublishButtonDisabled = placeholders
    ? (dashType === 'RADIO' || dashType === 'TV') && placeholders[dashType as keyof typeof placeholders]?.length !== 8
    : /* ||  ((dashType === "PRINT" || dashType === "OOH" || dashType === "CINEMA") && placeholders[dashType as keyof typeof placeholders]?.length !== 2) */ true;
  // console.log('isPublishButtonDisabled', isPublishButtonDisabled, dashType, placeholders)

  useEffect(() => {
    if (activeCampaign) {
      const fetchPublishedData = async () => {
        try {
          const response = (await API.graphql({
            query: getPublishedOutput,
            variables: {
              clientCode: activeCampaign?.ClientCode,
              clientCountry: activeCampaign?.ClientCountry,
              campaign_id: activeCampaign?.campaignId,
              dashboardType:
                dashType === DashboardTypes.INSOURCE_DATA
                  ? DashboardTypes.DIGITAL
                  : DashboardTypes[dashType as keyof typeof DashboardTypes],
              subCampaignId: activeSubCampaign?.subcampaignId?.toString(),
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })) as { data: GetPublishedOutputQuery };
          const placeholdersData = response.data.getPublishedOutput?.placeholders;
          if (placeholdersData) {
            console.log(placeholdersData);
            setPublishedData(placeholdersData as Placeholder[]);
          }
        } catch (e) {
          console.error(e);
        }
      };
      setToggleComment(false);
      fetchPublishedData();
    }
  }, [activeCampaign, activeSubCampaign?.subcampaignId, dashType]);

  async function handleRemoveQ() {
    try {
      const deleteQueries = (await API.graphql({
        query: deleteStoredQuery,
        variables: {
          campaign_id: activeSubCampaign?.name ? activeSubCampaign.subcampaignId : activeCampaign?.campaignId,
          Environment: getEnvironment,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: DeleteStoredQueryMutation };
      if (deleteQueries.data.deleteStoredQuery?.StatusCode === 200) {
        unpublishCampaign();
        window.location.reload();
      }
    } catch (e) {
      console.log('PROBLEM DELETING ALL QUERIES', e);
    }
  }

  interface IFilter {
    code: string;
    country: string;
  }
  const [a, setA] = useState<Array<IFilter>>([]);
  const handleClientCodes = (event: any) => {
    const obj: IFilter = {
      code: event.target.value,
      country: event.target.name,
    };
    event.target.checked
      ? setA((a) => [...a, obj])
      : setA((a) => [...a.filter((el) => !(el?.code === event.target.value && el?.country === event.target.name))]);
  };
  useEffect(() => {
    filterCampaignsPerClientCode(a);
  }, [a]);

  useEffect(() => {
    setClientFilterList([]);
  }, [activeOrganization]);

  //transform dashType so that first letter is uppercase and the rest is lowercase
  const dashTypeTransformed = dashType !== null && dashType?.charAt(0).toUpperCase() + dashType?.slice(1).toLowerCase();
  const [openFilter, setOpenFilter] = useState(false);

  // create date value in format YYYY-MM-DD for 1st of January of current year, 31st of December of current year, 3 months ago, 6 months ago and 18 months ago
  // create date value in format YYYY-MM-DD for 1st of January of current year, 31st of December of current year, 3 months ago, 6 months ago and 18 months ago
  const currentDate = new Date();
  //turn current date into a string in the format "yyyy-mm-dd"

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Add 1 to the month to get a 1-based index
  const currentDay = currentDate.getDate();
  const currentYearStartDate = new Date(currentYear, 0, 1).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  const currentYearEndDate = new Date(currentYear, 11, 31).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  const threeMonthsAgo = new Date(currentYear, currentMonth - 3, currentDay).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  const sixMonthsAgo = new Date(currentYear, currentMonth - 6, currentDay).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  const eighteenMonthsAgo = new Date(currentYear, currentMonth - 18, currentDay).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  // find the newst endDate from permanentCampaignList

  const getendDate = () => {
    if (permanentCampaignList) {
      const endDateArray = permanentCampaignList?.map((el) => el?.endDate as string);
      const maxEndDate =
        endDateArray &&
        (endDateArray?.reduce((a, b) => {
          return a > b ? a : b;
        }) as string);
      // add 5 days to the maxEndDate
      const newDate = new Date(maxEndDate);
      newDate.setDate(newDate.getDate() + 5);
      const newDateTransformed = newDate.toISOString().substring(0, 10);
      return newDateTransformed;
    }
  };
  /*   const endDateArray = permanentCampaignList && permanentCampaignList?.map((el) => el?.endDate as string)
    const maxEndDate = endDateArray && endDateArray?.reduce((a, b) => {
      return a > b ? a : b
    }) as string */
  // const currentDate = new Date().toISOString().split('T')[0];

  // const publishedCampaignList = ['2021-01-01', '2021-02-01', '2021-03-01', '2021-04-01'];
  // const publishedTime = publishedData.

  const date = publishedData
    ? publishedData.length > 0 && publishedData[0].publishedAt
      ? publishedData[0].publishedAt.split(' ')[0]
      : undefined
    : undefined;
  // const date = undefined

  const publishedCampaignList = date === undefined ? undefined : [date];

  const options = publishedCampaignList
    ? publishedCampaignList.map((date) => ({ value: date, label: `Published on ${date}` }))
    : [];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleCampaignChange = (option: any) => {
    setSelectedOption(option);
  };

  const { toggleDrag, isDragEnabled } = useTemplateContext();

  //hook that will check if SUMMARY HAS BEEN PUBLISHED
  const [isSummaryPublished] = useGetSummaryStatus(activeCampaign);
  const [bubleVisible, setBubleVisible] = useState(false);
  const handleBuble = () => {
    setBubleVisible(!bubleVisible);
    setTimeout(() => {
      setBubleVisible(false);
    }, 3000);
  };

  const [deleteLinkModal, setDeleteLinkModal] = useState(false);
  const handleDeleteConfirmationModal = (a: boolean) => {
    if (a) {
      handleRemoveQ();
      setDeleteLinkModal(false);
    } else {
      setDeleteLinkModal(a);
    }
  };

  const fullAccessOnThisClientCode =
    loggedInUserAccessType?.Access?.find((lev) => lev?.Level === 'FULL_ACCESS')?.Clients?.some(
      (client) => client?.ClientCode === activeCampaign?.ClientCode,
    ) || false;

  const canDoInsourceAndOnDigital = userCanDoInsource && dashType === DashboardTypes.DIGITAL;

  return (
    <StyledHeader>
      <Content key={activeCampaign?.campaign}>
        <div style={{ marginLeft: '0.6rem', marginTop: '0.6rem', marginBottom: '0.2rem' }}>
          <h3>{activeOrganization?.Organization ? activeOrganization?.Organization : 'Please select a client'}</h3>
        </div>

        {dashType === DashboardTypes.FLOWCHART ? (
          <IconButtonContainer style={{ display: 'flex', alignItems: 'flex-end', paddingTop: '7px' }}>
            {/* <Container style={{ display: 'flex', border: "1px solid red", alignItems: "flex-end" }}> */}
            {/* <DarkButton
              className="publish"
              style={{ alignSelf: 'flex-end', margin: "0" }}
              onClick={() => setSeeModal(true)}
            >
              Add Campaign
            </DarkButton> */}
            {/* </Container> */}
          </IconButtonContainer>
        ) : (
          <>
            <IconButtonContainer>
              {activeCampaign &&
                (fullAccessOnThisClientCode || canDoInsourceAndOnInsourcePortal) &&
                (dashType === DashboardTypes.DIGITAL || dashType === DashboardTypes.INSOURCE_DATA) && (
                  <IconButton onClick={toggleDrag}>{isDragEnabled ? 'Disable Dragging' : 'Enable Dragging'}</IconButton>
                )}

              {activeCampaign &&
                ((fullAccessOnThisClientCode && dashType === DashboardTypes.DIGITAL) ||
                  canDoInsourceAndOnInsourcePortal) && (
                  <>
                    <IconButton onClick={() => setPickTemplateDisplay(true)}>
                      <TbBookDownload />
                      Choose template
                    </IconButton>
                    <IconButton onClick={() => setSaveTemplateDisplay(true)}>
                      <TbBookUpload />
                      Save template
                    </IconButton>
                  </>
                )}
              {activeCampaign &&
                (statements?.Comment?.WRITE || canDoInsourceAndOnInsourcePortal) /* ||
                (activeCampaign &&
                  statements?.Comment?.READ &&
                  commentsList?.some((el) => el.placeholder.includes('0'))) */ && (
                  <>
                    <IconButton onClick={() => setToggleComment(!toggleComment)}>
                      <VscComment />
                      Comments
                    </IconButton>
                    {commentsList ? (
                      <Comments
                        header={true}
                        toggleComment={toggleComment}
                        componentID={
                          dashType === 'DIGITAL' || dashType === 'INSOURCE_DATA' ? '0' : `${dashTypeTransformed}0`
                        }
                        setToggleComment={(toggleComment: boolean) => setToggleComment(toggleComment)}
                      ></Comments>
                    ) : null}
                  </>
                )}
              {activeCampaign && (fullAccessOnThisClientCode || canDoInsourceAndOnInsourcePortal) && (
                <IconButton
                  onClick={() => {
                    setDeleteLinkModal(true);
                  }}
                >
                  <AiOutlineDelete />
                  Delete Stored
                </IconButton>
              )}

              {activeCampaign && (statements?.Queries?.PUBLISH || canDoInsourceAndOnInsourcePortal) && (
                <Container style={{ display: 'flex' }}>
                  {!isPublished && (statements?.Queries?.PUBLISH || canDoInsourceAndOnInsourcePortal) && (
                    <DarkButton
                      className="publish"
                      style={{ height: '50%', alignSelf: 'end' }}
                      onClick={() => publishCampaign()}
                      // disabled={isPublishButtonDisabled}
                    >
                      Publish
                    </DarkButton>
                  )}
                  {/*     {canDoInsourceAndOnDigital && (
                    <> */}
                  {((isPublished && statements?.Queries?.UNPUBLISH && !userCanDoInsource) ||
                    (isPublished && canDoInsourceAndOnInsourcePortal)) && (
                    /* !canDoInsourceAndOnDigital && */ <div
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <div style={{ fontSize: '12px' }}>
                        {publishedCampaignList !== undefined ? (
                          <>
                            {publishedCampaignList.length > 1 ? (
                              <CustomSelect
                                options={options}
                                value={selectedOption}
                                onChange={handleCampaignChange}
                                className="react-select-container"
                                id="published"
                                classNamePrefix="react-select"
                              />
                            ) : (
                              <div className="previewPublished">Published on {publishedCampaignList[0]}</div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {isSummaryPublished ? (
                        <div className="unpublishHolder">
                          <button onClick={handleBuble}>Unpublish</button>
                          <span className="buble">
                            <span className="bubletext" style={{ visibility: bubleVisible ? 'visible' : 'hidden' }}>
                              Summary has been published, please unpublish it first in order to unpublish this report.
                            </span>
                          </span>
                        </div>
                      ) : (
                        <button onClick={unpublishCampaign}>Unpublish</button>
                      )}
                    </div>
                  )}
                  {/*      </>
                  )} */}
                </Container>
              )}
            </IconButtonContainer>
          </>
        )}
      </Content>

      <Content key={activeOrganization?.Organization}>
        {/* <div> */}
        {dashType !== DashboardTypes.FLOWCHART && (
          <Filtering>
            <SVG_FILTER onClick={() => setOpenFilter(!openFilter)} />
            <h1 onClick={() => setOpenFilter(!openFilter)}>Filters</h1>
          </Filtering>
        )}

        {openFilter && (
          <div style={{ display: openFilter ? 'block' : 'none' }}>
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: '100',
                content: '',
              }}
              onClick={() => setOpenFilter(!openFilter)}
            ></div>
            <FilterContainer>
              <button type="button" className="x" onClick={() => setOpenFilter(false)}>
                <IoMdClose className="closeIcon" />
              </button>

              <section>
                <h1 className="title">Client Filter</h1>
                <ClientsContainer>
                  {reducedClientList?.map((e, index) => (
                    <Selections key={e?.campaignId}>
                      <input
                        type="checkbox"
                        checked={clientFilterList.some(
                          (el) => el?.code === e?.ClientCode && el?.country === e?.ClientCountry,
                        )}
                        value={e?.ClientCode as string}
                        name={e?.ClientCountry as string}
                        onChange={handleClientCodes}
                      />
                      <span className="tooltip">
                        {e?.ClientCode} {e?.ClientCountry}
                        <span className="tooltiptext">{e?.ClientName}</span>
                      </span>
                    </Selections>
                  ))}
                </ClientsContainer>
              </section>

              <section>
                <h1 className="title">Date Filter</h1>
                <div className="sectionContent" style={{ flexDirection: 'column' }}>
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '1vw', gap: '.1vw' }}>
                      <h5>Start Date</h5>
                      {/* <input value={startDateFilter} onChange={(e) => setStartDateFilter(e.target.value)} type="date" /> */}
                      <DatePicker
                        className="datepicker"
                        dateFormat="dd-MM-yyyy"
                        selected={startDateFilter}
                        onChange={(e: any) => setStartDateFilter(e.toISOString().slice(0, 10))}
                        calendarStartDay={1}
                      />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <h5>End Date</h5>
                      {/* <input value={endDateFilter} onChange={(e) => setEndDateFilter(e.target.value)} type="date" /> */}
                      <DatePicker
                        className="datepicker"
                        dateFormat="dd-MM-yyyy"
                        selected={endDateFilter}
                        onChange={(e: any) => setEndDateFilter(e.toISOString().slice(0, 10))}
                        calendarStartDay={1}
                      />
                    </div>
                  </div>
                  <div style={{ flexDirection: 'row', display: 'flex', gap: '5px', marginTop: '5px' }}>
                    <button
                      type="button"
                      onClick={() => {
                        setStartDateFilter(currentYearStartDate);
                        setEndDateFilter(currentYearEndDate);
                      }}
                      style={{ fontSize: '12px', padding: '3px 6px', borderRadius: '5px' }}
                    >
                      Current year
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setStartDateFilter(eighteenMonthsAgo);
                        setEndDateFilter(getendDate() as string);
                      }}
                      style={{ fontSize: '12px', padding: '3px 6px', borderRadius: '5px' }}
                    >
                      Max
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setStartDateFilter(threeMonthsAgo);
                        setEndDateFilter(currentDate.toISOString().substring(0, 10));
                      }}
                      style={{ fontSize: '12px', padding: '3px 6px', borderRadius: '5px' }}
                    >
                      Last 3 months
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setStartDateFilter(sixMonthsAgo);
                        setEndDateFilter(currentDate.toISOString().substring(0, 10));
                      }}
                      style={{ fontSize: '12px', padding: '3px 6px', borderRadius: '5px' }}
                    >
                      Last 6 months
                    </button>
                  </div>
                  <div></div>
                </div>
              </section>

              <section>
                <h1 className="title">Sort by Date</h1>

                <ClientsContainer>
                  <Selections htmlFor="desc">
                    <input name="order" value="desc" id="desc" type="radio" defaultChecked={order === 'desc'} />
                    <span onClick={() => setOrder('desc')}>NEWEST FIRST</span>
                  </Selections>

                  <Selections htmlFor="asc">
                    <input name="order" value="asc" type="radio" id="asc" defaultChecked={order === 'asc'} />
                    <span onClick={() => setOrder('asc')}>OLDEST FIRST</span>
                  </Selections>
                </ClientsContainer>
              </section>
            </FilterContainer>
          </div>
        )}

        <CampaignSelectComponent clientFilterList={clientFilterList}></CampaignSelectComponent>

        <div>
          {statements?.Queries?.CREATE && dashType === 'TV' && activeCampaign && (
            <IconButtonContainer style={{ gap: '1vw' }}>
              {TVKPI && Object.keys(TVKPI).length > 0 && (
                <h1 style={{ fontSize: '12px', fontFamily: 'fontBold' }}>{`${new Date(
                  TVKPI.Startdate,
                ).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })} - ${new Date(TVKPI.Enddate).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })} | ${TVKPI.primary_target_group} | POP:${new Intl.NumberFormat('en-DK', {
                  maximumFractionDigits: 0,
                }).format(TVKPI.universe_primary_target)}`}</h1>
              )}
              {networkList.length > 0 && (
                <select
                  style={{ marginRight: '4px' }}
                  name=""
                  id=""
                  onChange={(e) => setTVStationSelector(e.target.value)}
                >
                  <option value="All stations">All stations</option>
                  {networkList.map((net: any) => (
                    <option key={net} value={net}>
                      {net}
                    </option>
                  ))}
                </select>
              )}
            </IconButtonContainer>
          )}
          {statements?.Queries?.CREATE && dashType === 'RADIO' && activeCampaign && (
            <IconButtonContainer style={{ gap: '1vw' }}>
              {RADIOKPI && Object.keys(RADIOKPI).length > 0 && (
                <h1 style={{ fontSize: '12px', fontFamily: 'fontBold' }}>{`${new Date(
                  RADIOKPI.Startdate,
                ).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })} - ${new Date(RADIOKPI.Enddate).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })} | ${RADIOKPI.primary_target_group} | POP:${new Intl.NumberFormat('en-DK', {
                  maximumFractionDigits: 0,
                }).format(RADIOKPI.universe_primary_target)}`}</h1>
              )}

              {networkList.length > 0 && (
                <select
                  style={{ marginRight: '4px' }}
                  name=""
                  id=""
                  onChange={(e) => setRadioStationSelector(e.target.value)}
                >
                  <option value="All stations">All stations</option>
                  {networkList.map((net: any) => (
                    <option key={net} value={net}>
                      {net}
                    </option>
                  ))}
                </select>
              )}
            </IconButtonContainer>
          )}
          {statements?.Queries?.CREATE && dashType === 'PRINT' && activeCampaign && (
            <IconButtonContainer style={{ gap: '1vw' }}>
              {PRINTKPI && Object.keys(PRINTKPI).length > 0 && (
                <h1 style={{ fontSize: '12px', fontFamily: 'fontBold' }}>{`${new Date(
                  PRINTKPI.Startdate,
                ).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })} - ${new Date(PRINTKPI.Enddate).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })} `}</h1>
              )}
            </IconButtonContainer>
          )}
          {statements?.Queries?.CREATE && dashType === 'OOH' && activeCampaign && (
            <IconButtonContainer style={{ gap: '1vw' }}>
              {OOHKPI && Object.keys(OOHKPI).length > 0 && (
                <h1 style={{ fontSize: '12px', fontFamily: 'fontBold' }}>{`${new Date(
                  OOHKPI.Startdate,
                ).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })} - ${new Date(OOHKPI.Enddate).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })} `}</h1>
              )}
            </IconButtonContainer>
          )}
        </div>
        {/* </div> */}
        {dashType === DashboardTypes.FLOWCHART && (
          <IconButtonContainer style={{ display: 'flex', alignItems: 'flex-end' }}>
            <DarkButton className="publish" style={{ alignSelf: 'flex-end' }} onClick={() => setSeeModal(true)}>
              Add Campaign
            </DarkButton>
          </IconButtonContainer>
        )}
        {dashType !== DashboardTypes.FLOWCHART &&
          activeCampaign &&
          !activeCampaign.authorized &&
          !userCanDoInsource &&
          statements?.Comment?.READ &&
          commentsList?.some((el) => el.placeholder.includes('0')) && (
            <>
              <IconButton onClick={() => setToggleComment(!toggleComment)}>
                <VscComment />
                Comments
              </IconButton>
              {commentsList ? (
                <Comments
                  header={true}
                  toggleComment={toggleComment}
                  componentID={dashType === 'DIGITAL' ? '0' : `${dashTypeTransformed}0`}
                  setToggleComment={(toggleComment: boolean) => setToggleComment(toggleComment)}
                ></Comments>
              ) : null}
            </>
          )}
      </Content>

      {pickTemplateDisplay && (
        <PickTemplate setPickTemplateDisplay={(arg: any) => setPickTemplateDisplay(arg)}></PickTemplate>
      )}

      {saveTemplateDisplay && (
        <SaveTemplate setSaveTemplateDisplay={(arg: any) => setSaveTemplateDisplay(arg)}></SaveTemplate>
      )}

      {seeModal && (
        <AddCampaignModal setSeeModal={(seeModal: any) => setSeeModal(seeModal)} seeModal={seeModal}></AddCampaignModal>
      )}
      {editCampaign && <EditCampaignModal></EditCampaignModal>}
      {deleteLinkModal && (
        <DeleteConfirmationModal
          handleDeleteConfirmationModal={handleDeleteConfirmationModal}
          modalObject={{
            title: 'Delete All Stored Queries',
            message: 'This will delete all stored queries, are you sure you want to proceed?',
            mainButton: 'Delete',
            secondaryButton: 'Cancel',
          }}
        ></DeleteConfirmationModal>
      )}
    </StyledHeader>
  );
};

export default Header;
