import { DashboardTypes, GetPublishedOutputQuery, Placeholder, queryInput } from 'API';
import { API } from 'aws-amplify';
import { useCampaignContext } from 'context/CampaignContext';
import { getPublishedOutput } from 'graphql/queries';
import { set } from 'lodash';
import { useEffect, useState } from 'react';

export const useGetSummaryStatus = (activeCampaign: any): readonly [boolean, any] => {
  const { REACT_APP_AGENCY } = process.env;
  // const { activeCampaign } = useCampaignContext()
  const [summaryStatus, setSummaryStatus] = useState<boolean>(false);
  const [placeholdersData, setPlaceholdersData] = useState<Placeholder[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = (await API.graphql({
          query: getPublishedOutput,
          variables: {
            clientCode: activeCampaign?.ClientCode,
            clientCountry: activeCampaign?.ClientCountry,
            campaign_id: activeCampaign?.campaignId,
            dashboardType: DashboardTypes.FLOWCHART,
            subCampaignId: -1,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetPublishedOutputQuery };
        const placeholdersData = response.data.getPublishedOutput?.placeholders;
        console.log('placeholdersData2222', placeholdersData, activeCampaign);
        if (placeholdersData) {
          if (
            placeholdersData.length > 0 &&
            (activeCampaign.status === 'PUBLISHED' || activeCampaign.status === 'CAMPAIGN_PUBLISHED')
          ) {
            setPlaceholdersData(placeholdersData as Placeholder[]);
            setSummaryStatus(true);
          } else {
            setSummaryStatus(false);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (activeCampaign) {
      fetch();
    } else {
      setSummaryStatus(false);
      setPlaceholdersData([]);
    }
  }, [activeCampaign]);

  return [summaryStatus, placeholdersData];
};
