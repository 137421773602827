import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCampaignContext } from 'context/CampaignContext';
import { MainContainer, Kpis, Graphs, AudienceReport, TxtContainer } from 'components/Main/Main.styles';

import ARContainer from 'components/Main/Gallery/ARContainer';
import { KPItoS3 } from 'components/KPItoS3';
import {
  metricFunction,
  filterDetail,
  filtertype,
  datatype,
  queryInput,
  countryEnum,
  agencyEnum,
  localQuery,
  metric,
} from 'API';
import AuthContext from 'context/AuthContext';
import { useClientContext } from 'context/ClientContext';
import { useTheme } from 'styled-components';
import ReactLoading from 'react-loading';
import { PublishContext } from 'context/PublishContext';
import { useDataQuery, OOHPRINT } from 'customHooks/useDataQuery';
import { useTemplateContext } from 'context/TemplateContext';
import { KPIholder } from 'components/newSetup/TV/TV.styles';
import { useDataSources } from 'customHooks/useDataSources';
import { useGetStoredQueriesDetails } from 'customHooks/useGetStoredQueriesDetails';
import PlaceholderContainer from '../PlaceholderContainer';
import ClientPlaceholderContainer from '../CLIENTCOMPONENTS/ClientPlaceholderContainer';
// import PreviewTV from 'assets/Preview/PreviewTV';
// import { StyledGraphContainer } from 'components/Main/graf/GraphStyles';
// import { TxtContainer2, Add } from 'components/Main/table/TableStyles';
import { getEndDate, getStartDate } from 'methods/dateMethods';
import NoCampaignSelected from '../NoCampaignSelected';
import InputContext from 'context/InputContext';
import { a } from 'aws-amplify';
import { IImageList, useImageList } from 'customHooks/useImageList';
import KPIComponent from '../KPIComponent';

export const Alert = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
  text-align: center;
  .text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: white;
  }
`;

const OOHMain = () => {
  const { updateOLArray, publishedData } = useContext(PublishContext);
  const { activeCampaign, campaignList } = useCampaignContext();
  const { user } = useContext(AuthContext);
  const { activeOrganization, organizationList } = useClientContext();
  const theme = useTheme();
  const { REACT_APP_AGENCY } = process.env;
  const { updateTemplatePlaceholderList } = useTemplateContext();
  const { fetchedPlaceholders } = useTemplateContext();
  const newPlaceholder = undefined;
  const { setCapture } = useContext(InputContext);

  const [dataSources] = useDataSources(activeCampaign);
  const [storedQueries, , , , , , publishedQueries] = useGetStoredQueriesDetails(
    activeCampaign,
    newPlaceholder,
    fetchedPlaceholders,
    publishedData,
  );

  const activeCamp = activeCampaign;
  // const [activeCamp] = useActiveCampaign({ campaignList: campaignList, orgList: organizationList })

  const username = user?.username;
  const Organization = activeOrganization?.Organization;
  const ClientCountry = activeCamp?.ClientCountry;
  const isDemo = Organization === 'DEMO-Organization';
  const [query, setQuery] = useState<queryInput>();

  const publishedKPI = publishedData?.find((element: any) => element.position === 'OOH0');
  const publishedOutputLocation = publishedKPI?.outputlocation;

  useEffect(() => {
    if (activeCamp && Object.keys(activeCamp).length > 0 && activeCamp?.authorized === true) {
      //METRICS DATA
      const metrics: metric[] = [
        {
          column: 'ctc',
          name: 'ctc',
          aggregation: metricFunction.SUM,
          id: '1',
        },
        {
          column: 'date',
          name: 'Startdate',
          aggregation: metricFunction.MIN,
          decimals: -1,
          id: '2',
        },
        {
          column: 'date',
          name: 'Enddate',
          aggregation: metricFunction.MAX,
          decimals: -1,
          id: '3',
        },
      ];

      // FILTER(CAMPAIGN DATA)
      const filter: filterDetail = {
        column: 'campaign_id',
        filter: {
          filtertype: filtertype.EQUAL,
          datatype: datatype.NUMBER,
          value: activeCamp?.campaignId?.toString(),
        },
      };

      const localQuery: localQuery = {
        filters: [filter],
        metrics: metrics,
        // dimensions: ['campaign_id'],
        detailedDimensions: [
          {
            column: 'campaign_id',
            name: 'campaign_id',
            hidden: false,
            id: '4',
          },
        ],
        orderBy: ['1', '2', '3', '4'],
        preview: 100,
      };

      const newQuery: queryInput = {
        query: localQuery,
        views: [
          {
            viewName:
              Organization === 'DEMO-Organization' ? 'fozzie_outdoor_demo' : `fozzie_outdoor_${REACT_APP_AGENCY}`,
            database:
              activeCamp?.ClientCountry === 'DK'
                ? 'data_lake_denmark'
                : activeCamp?.ClientCountry === 'SE'
                ? 'data_lake_sweden'
                : activeCamp?.ClientCountry === 'FI'
                ? 'data_lake_finland'
                : 'data_lake_norway',
          },
        ],
        jobnumber: 'ANNA0027',
        clients: [
          {
            country: countryEnum[activeCamp?.ClientCountry as keyof typeof countryEnum],
            client: activeCamp?.ClientCode as string,
          },
        ],
        date: { from: getStartDate(), to: getEndDate() },
        user: {
          name: username,
          agency: agencyEnum[`${REACT_APP_AGENCY?.toUpperCase()}` as keyof typeof agencyEnum],
          country: countryEnum.DK,
        },
      };
      setQuery(newQuery);
    }
  }, [activeCamp, REACT_APP_AGENCY, isDemo, ClientCountry, username, Organization]);
  // console.log("THIS IS A FUCKING OOH QUERY KPI", query)
  const [data, queryStatus, error, queryId, outputLocation] = useDataQuery({
    query: query,
    querySource: 'OOH KPI',
    publishedOutputLocation: publishedOutputLocation,
  });

  const jsonDataObject = data as OOHPRINT;
  const ctc = jsonDataObject ? jsonDataObject.ctc : NaN;
  /*  const Enddate = jsonDataObject ? jsonDataObject.Enddate : null
        const Startdate = jsonDataObject ? jsonDataObject.Startdate : null */

  useEffect(() => {
    if (outputLocation) {
      const placeholdersObject = {
        position: 'OOH0',
        outputlocation: outputLocation,
      };
      updateOLArray(placeholdersObject);
    }
  }, [outputLocation, updateOLArray]);

  useEffect(() => {
    if (queryId) {
      const a = {
        Placeholder: 'OOH0',
        Location: queryId,
        Placeholder_titel: '',
      };
      updateTemplatePlaceholderList(a);
    }
  }, [queryId]);

  const componentIDs = [
    ['OOH3.1', 'OOH3.2', 'OOH3.3', 'OOH3.4', 'OOH3.5'],
    ['OOH4.1', 'OOH4.2', 'OOH4.3', 'OOH4.4', 'OOH4.5'],
    ['OOH5.1', 'OOH5.2', 'OOH5.3', 'OOH5.4', 'OOH5.5'],
  ];
  const [uploadComponents, setUploadComponents] = useState<IImageList[][] | undefined>(undefined);

  const [list] = useImageList({ storedQueries: storedQueries, source: 'OOH' });
  useEffect(() => {
    if (dataSources.length > 0) {
      setUploadComponents(list);
    } else {
      setUploadComponents(undefined);
    }
  }, [dataSources, list]);

  const kpiData = [
    { title: 'Budget', subtitle: 'Realized', ctc: ctc, componentID: 'OOHKPI1' },
    { title: 'Reach', subtitle: 'Realized', location: 'OOH', componentID: 'OOHKPI2' },
    { title: 'Persons', subtitle: 'Total Number', location: 'OOH', componentID: 'OOHKPI3' },
    { title: 'Frequency', subtitle: 'Average', location: 'OOH', componentID: 'OOHKPI4' },
  ];

  return (
    <>
      {activeCampaign?.authorized && (
        <button className="PDFbutton" onClick={() => setCapture(true)}>
          Download PPT
        </button>
      )}
      {activeCampaign && storedQueries && storedQueries.length > 0 ? (
        <MainContainer key={activeCampaign?.campaign}>
          {queryStatus === 'FAILED' ? (
            <Kpis>
              <KPIholder style={{ padding: '1vw' }}>
                <p>Something went wrong, please reload the page and try again </p>
              </KPIholder>
            </Kpis>
          ) : (
            <Kpis>
              {/*               <KPIholder>
                {queryStatus === 'RUNNING' ? (
                  <TxtContainer className="loader">
                    <ReactLoading type="spinningBubbles" color={theme.publishButton} />
                  </TxtContainer>
                ) : (
                  <TxtContainer>
                    <h1>Budget</h1>
                    <h2>Realized</h2>
                    <div className="valueFlex">
                      <h4 className="tooltip">
                        {Intl.NumberFormat('en-DK').format(ctc)}
                        <span className="tooltiptext">{Intl.NumberFormat('en-DK').format(ctc)}</span>
                      </h4>
                    </div>
                  </TxtContainer>
                )}
              </KPIholder>
              <KPIholder>
                {queryStatus === 'RUNNING' ? (
                  <TxtContainer className="loader">
                    <ReactLoading type="spinningBubbles" color={theme.publishButton} />
                  </TxtContainer>
                ) : (
                  <TxtContainer>
                    <h1>Reach</h1>
                    <h2>Realized</h2>
                    <KPItoS3 location={'OOH'} componentID={'OOHKPI2'}></KPItoS3>
                  </TxtContainer>
                )}
              </KPIholder>

              <KPIholder>
                {queryStatus === 'RUNNING' ? (
                  <TxtContainer className="loader">
                    <ReactLoading type="spinningBubbles" color={theme.publishButton} />
                  </TxtContainer>
                ) : (
                  <TxtContainer>
                    <h1>Persons</h1>
                    <h2>Total Number</h2>
                    <KPItoS3 location={'OOH'} componentID={'OOHKPI3'}></KPItoS3>
                  </TxtContainer>
                )}
              </KPIholder>
              <KPIholder>
                {queryStatus === 'RUNNING' ? (
                  <TxtContainer className="loader">
                    <ReactLoading type="spinningBubbles" color={theme.publishButton} />
                  </TxtContainer>
                ) : (
                  <TxtContainer>
                    <h1>Frequency</h1>
                    <h2>Average</h2>
                    <KPItoS3 location={'OOH'} componentID={'OOHKPI4'}></KPItoS3>
                  </TxtContainer>
                )}
              </KPIholder> */}
              {kpiData.map((kpi, index) => (
                <KPIComponent
                  key={kpi.componentID}
                  queryStatus={queryStatus}
                  title={kpi.title}
                  subtitle={kpi.subtitle}
                  location={kpi.location}
                  componentID={kpi.componentID}
                  ctc={kpi.ctc}
                  theme={theme}
                />
              ))}
            </Kpis>
          )}

          {activeCamp?.authorized ? (
            <Graphs dashboard={'OOH'} columnsNumber={1}>
              {storedQueries
                .filter((el) => el?.position?.startsWith('OOH1'))
                .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                .map((e) => (
                  <PlaceholderContainer
                    key={e.position}
                    componentID={e.position as string}
                    placeholderId={e.position as string}
                    dataSources={dataSources}
                    storedQueryDetails={e}
                    isGraph={true}
                  ></PlaceholderContainer>
                ))}
            </Graphs>
          ) : (
            <Graphs key={publishedQueries[0]?.outputlocation ?? ''} dashboard={'OOH'} columnsNumber={1}>
              {publishedQueries
                .filter((el) => el?.position?.startsWith('OOH1'))
                .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                .map((e) => (
                  <ClientPlaceholderContainer
                    key={e.position}
                    publishedQueriesDetails={e}
                    componentID={e.position as string}
                    isGraph={true}
                  ></ClientPlaceholderContainer>
                ))}
            </Graphs>
          )}
          {activeCamp?.authorized ? (
            <>
              {uploadComponents?.map((ids, index) => (
                <AudienceReport key={index}>
                  {ids.map((id: any) => (
                    <ARContainer
                      key={id.id}
                      componentID={id.id}
                      oldComponentID={
                        id.id === 'OOH4.1' && storedQueries.some((el) => el?.position === 'OOH3.6')
                          ? 'OOH3.6'
                          : id.id === 'OOH4.2' && storedQueries.some((el) => el?.position === 'OOH3.7')
                          ? 'OOH3.7'
                          : id.id === 'OOH4.3' && storedQueries.some((el) => el?.position === 'OOH3.8')
                          ? 'OOH3.8'
                          : null
                      }
                      visible={id.visible}
                      setUploadComponents={setUploadComponents}
                      uploadComponents={uploadComponents}
                    />
                  ))}
                </AudienceReport>
              ))}
            </>
          ) : (
            <>
              {componentIDs.map((ids, index) => (
                <AudienceReport key={index}>
                  {ids.map((id) => (
                    <ARContainer
                      key={id}
                      componentID={id}
                      oldComponentID={
                        id === 'OOH4.1' && publishedData.some((el) => el?.position === 'OOH3.6')
                          ? 'OOH3.6'
                          : id === 'OOH4.2' && publishedData.some((el) => el?.position === 'OOH3.7')
                          ? 'OOH3.7'
                          : id === 'OOH4.3' && publishedData.some((el) => el?.position === 'OOH3.8')
                          ? 'OOH3.8'
                          : null
                      }
                    />
                  ))}
                </AudienceReport>
              ))}
            </>
          )}
          {/* <AudienceReport>
                        <ARContainer componentID={'OOH3.1'}></ARContainer>
                        <ARContainer componentID={'OOH3.2'}></ARContainer>
                        <ARContainer componentID={'OOH3.3'}></ARContainer>
                        <ARContainer componentID={'OOH3.4'}></ARContainer>
                    </AudienceReport>


                    <AudienceReport>
                        <ARContainer componentID={'OOH3.5'}></ARContainer>
                        <ARContainer componentID={'OOH3.6'}></ARContainer>
                        <ARContainer componentID={'OOH3.7'}></ARContainer>
                        <ARContainer componentID={'OOH3.8'}></ARContainer>
                    </AudienceReport> */}

          {/* <Tables>
                <TableContainer
                    componentID={'Radio3.1'}
                    placeholderId={"Radio3.1"}
                    setTablePlaceholderList={(arg: any) => console.log("a")}></TableContainer>
            </Tables>
 */}
        </MainContainer>
      ) : (
        <NoCampaignSelected page={'Out of Home'}></NoCampaignSelected>
      )}
    </>
  );
};

export default OOHMain;
