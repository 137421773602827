import { BottomRow, NavigationButton, Preview, PreviewMidRow } from 'components/Main/table/TableStyles';
import { DarkButton } from 'components/style/Global';
import React, { useState } from 'react';
import { GoChevronLeft } from 'react-icons/go';
import DataTable from './DataTable';
import { useCampaignContext } from 'context/CampaignContext';
import ChartsContainer from './ChartsContainer';
import { StoreQueriesMutation, UserQuery } from 'API';
import { API } from 'aws-amplify';
import { storeQueries } from 'graphql/mutations';
import { set } from 'lodash';
import { useGetMainTotalQuery } from 'customHooks/useGetMainTotalQuery';

import TVChartsContainer from './TV/TVChartsContainer';
import RadioChartsContainer from './RADIO/RadioChartsContainer';
import OOHChartsContainer from './OOH&PRINT/OOHContainer';
import OOHChartsContainer2 from './OOH&PRINT/OOHContainer';
import { ErrorBoundary } from 'react-error-boundary';
import { PlaceholderType } from './PlaceholderContainer';
import { useTemplateContext } from 'context/TemplateContext';

type Props = {
  isGraph: boolean;
  joinedData: any[] | undefined;
  title: string;
  setTitle(title: string): void;
  page: number;
  ccData: any;
  userMainQuery: any;
  userCCQuery: any;
  nextPage(): any;
  previousPage(): any;
  chartMetrics: any;
  componentID: string;
  setChartMetrics(chartMetric: any): any;
  mainQueryID: string;
  ccQueryID: string;
  element: PlaceholderType | undefined;
  placeholderList: PlaceholderType[] | undefined;
  setTablePlaceholderList(value: React.SetStateAction<PlaceholderType[]>): void;
  setStoredQueries: React.Dispatch<React.SetStateAction<UserQuery[]>>;

  setUserMainQuery(query: any): any;
  setUserCCQuery(query: any): any;
  setUserCCTotalQuery(query: any): any;
  setUserMainQueryTotal(query: any): any;
  queryError: string;
  setPage(page: number): any;
  setNewQuery(newQuery: 'OLD' | 'NEW' | 'BACK' | 'APPLY'): any;
  savePlaceholderToOrder2: (componentId: string) => void;
  updateTablePlaceholderDuplicate: (
    queryId: string,
    id: string,
    isDupicated: boolean | undefined,
    newOriginalId?: string | undefined,
  ) => void;
  defaultSelected: boolean;
};

const PreviewContainer = (props: Props) => {
  const {
    isGraph,
    joinedData,
    title,
    setTitle,
    page,
    ccData,
    userCCQuery,
    userMainQuery,
    nextPage,
    previousPage,
    chartMetrics,
    setChartMetrics,
    componentID,
    mainQueryID,
    element,
    ccQueryID,
    setUserMainQuery,
    setUserCCQuery,
    setUserCCTotalQuery,
    setUserMainQueryTotal,
    queryError,
    setPage,
    placeholderList,
    setStoredQueries,
    setTablePlaceholderList,
    savePlaceholderToOrder2,
    updateTablePlaceholderDuplicate,
    defaultSelected,
  } = props;
  const { dashType, activeSubCampaign, activeCampaign } = useCampaignContext();
  const [isDifferent, setIsDifferent] = useState(false);
  // SECOND QUERY AND ITS TOTAL
  const [secondMainQuery, setSecondMainQuery] = useState<any>();
  const [mainQueryTotal] = useGetMainTotalQuery(secondMainQuery, 'MAIN');
  // SECOND QUERY CC AND  ITS TOTAL
  const [secondCCQuery, setSecondCCQuery] = useState<any>();
  const [ccQueryTotal] = useGetMainTotalQuery(secondCCQuery, 'CC');
  const { fetchedPlaceholders } = useTemplateContext();
  const handleChanges = (secondQuery: any, secondQueryConversions: any) => {
    if (
      JSON.parse(JSON.stringify(secondQuery)) !== JSON.parse(JSON.stringify(userMainQuery)) ||
      JSON.parse(JSON.stringify(secondQueryConversions)) !== JSON.parse(JSON.stringify(userCCQuery))
    ) {
      setIsDifferent(true);
      setSecondMainQuery(secondQuery);
      setSecondCCQuery(secondQueryConversions);
    } else {
      console.log('SAME');
    }
  };

  console.log(props.chartMetrics);
  const handleSave = async () => {
    await saveQuery();
    console.log(props.chartMetrics);
    async function saveQuery() {
      try {
        // const correctComponentID = element?.original_id ? element.original_id : componentID;
        const correctComponentID = componentID;

        const varNoConversions = {
          campaign_id: activeSubCampaign?.name
            ? activeSubCampaign.subcampaignId?.toString()
            : activeCampaign?.campaignId?.toString(),
          position: correctComponentID,
          query_id: mainQueryID,
          title: props.title,
          metrics: props.chartMetrics.map((m: any) => {
            return {
              chartType: m.chartType,
              metric: m.metric,
              axis: m.axis,
            };
          }),
        };

        const varWithConversions = {
          campaign_id: activeSubCampaign?.name
            ? activeSubCampaign.subcampaignId?.toString()
            : activeCampaign?.campaignId?.toString(),
          position: correctComponentID,
          query_id: mainQueryID,
          query_id_cc: ccQueryID,
          title: props.title,
          metrics: props.chartMetrics.map((m: any) => {
            return {
              chartType: m.chartType,
              metric: m.metric,
              axis: m.axis,
            };
          }),
        };

        // console.log("KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK", correctComponentID)
        const saveResponse = (await API.graphql({
          query: storeQueries,
          variables: ccQueryID !== '' && ccData ? varWithConversions : varNoConversions,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: StoreQueriesMutation };

        savePlaceholderToOrder2(correctComponentID);
        updateTablePlaceholderDuplicate(mainQueryID, correctComponentID, element?.duplicated);
        // element?.duplicated === true &&

        setPage(7);

        nextPage();
      } catch (e) {
        console.error('ERROR SAVING QUERY', e);
      }
    }
  };
  return (
    <Preview displayPreview={6}>
      <PreviewMidRow style={{ justifyContent: queryError !== '' ? 'left' : 'center' }}>
        {queryError !== '' ? (
          <div style={{ paddingTop: queryError !== '' ? '1.5vw' : 'normal' }}>
            <h1 style={{ fontSize: '1.2vw', paddingBottom: '.4vw' }}>
              {queryError} <br />
            </h1>
            <p>Please click Start New Query to redefine your query, otherwise this placeholder will be ignored</p>
          </div>
        ) : (
          <>
            {joinedData && !isGraph && (
              <DataTable
                data={joinedData}
                title={title}
                setTitle={setTitle}
                page={page}
                userMainQuery={userMainQuery}
                userCCQuery={userCCQuery}
                conversionsData={ccData}
                handleChanges={handleChanges}
              ></DataTable>
            )}

            {joinedData && isGraph && (dashType === 'DIGITAL' || dashType === 'INSOURCE_DATA') && (
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <ChartsContainer
                  data={joinedData}
                  title={title}
                  setTitle={setTitle}
                  page={page}
                  currentQuery={userMainQuery}
                  currentConversionsQuery={userCCQuery}
                  conversionsData={ccData}
                  chartMetrics={chartMetrics}
                  setChartMetrics={(chartMetric) => setChartMetrics(chartMetric)}
                  handleChanges={handleChanges}
                  componentID={componentID}
                ></ChartsContainer>
              </ErrorBoundary>
            )}

            {
              <>
                {joinedData && isGraph && dashType === 'TV' && (
                  <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <TVChartsContainer
                      data={joinedData}
                      componentID={componentID}
                      page={page}
                      title={title}
                      setTitle={(title) => props.setTitle(title)}
                      chartMetrics={props.chartMetrics}
                      setChartMetrics={(chartMetric) => props.setChartMetrics(chartMetric)}
                      currentQuery={userMainQuery}
                      currentConversionsQuery={userCCQuery}
                      conversionsData={ccData}
                      handleChanges={handleChanges}
                    ></TVChartsContainer>
                  </ErrorBoundary>
                )}

                {joinedData && isGraph && dashType === 'RADIO' && (
                  <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <RadioChartsContainer
                      data={joinedData}
                      componentID={componentID}
                      page={page}
                      title={title}
                      setTitle={(title) => props.setTitle(title)}
                      chartMetrics={props.chartMetrics}
                      setChartMetrics={(chartMetric) => props.setChartMetrics(chartMetric)}
                      currentQuery={userMainQuery}
                      currentConversionsQuery={userCCQuery}
                      conversionsData={ccData}
                      handleChanges={handleChanges}
                    ></RadioChartsContainer>
                  </ErrorBoundary>
                )}

                {joinedData && isGraph && (dashType === 'OOH' || dashType === 'PRINT' || dashType === 'CINEMA') && (
                  <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <OOHChartsContainer2
                      data={joinedData}
                      componentID={props.componentID}
                      page={props.page}
                      title={props.title}
                      setTitle={(title) => props.setTitle(title)}
                      chartMetrics={props.chartMetrics}
                      setChartMetrics={(chartMetric) => props.setChartMetrics(chartMetric)}
                      currentQuery={userMainQuery}
                      currentConversionsQuery={userCCQuery}
                      conversionsData={ccData}
                      // handleChanges={handleChanges}
                    ></OOHChartsContainer2>
                  </ErrorBoundary>
                )}
              </>
            }
          </>
        )}
      </PreviewMidRow>

      {joinedData && (
        <BottomRow isGraph={props.isGraph}>
          {queryError === '' ? (
            <NavigationButton
              type="button"
              style={{ backgroundColor: 'transparent' }}
              /* onClick={props.handleBackButton} */ onClick={() => {
                /* props.setNewQuery("BACK"); */ previousPage();
              }}
            >
              <GoChevronLeft /> Back
            </NavigationButton>
          ) : (
            <NavigationButton
              type="button"
              style={{ backgroundColor: 'transparent' }}
              onClick={() => {
                // setUserMainQuery(undefined)
                // setUserCCQuery(undefined)
                // setUserCCTotalQuery(undefined)
                // setUserMainQueryTotal(undefined)
                setPage(0);
              }}
            >
              <GoChevronLeft /> Start New Query
            </NavigationButton>
          )}

          {joinedData && !isDifferent && queryError === '' && (
            <DarkButton
              className="scaleHover"
              style={{ width: '150px', display: joinedData.length === 0 ? 'hidden' : 'block' }}
              type="button"
              onClick={handleSave}
            >
              Save
            </DarkButton>
          )}
          {isDifferent && (
            <DarkButton
              className="scaleHover"
              style={{ width: '150px' }}
              type="button"
              onClick={() => {
                setUserMainQuery(secondMainQuery);
                setUserCCQuery(secondCCQuery);
                setUserMainQueryTotal(mainQueryTotal);
                setUserCCTotalQuery(ccQueryTotal);
                if (element?.duplicated === true) {
                  updateTablePlaceholderDuplicate &&
                    updateTablePlaceholderDuplicate(mainQueryID, element.id, false, element.id);
                }
                props.setNewQuery('APPLY');
              }}
            >
              APPLY
            </DarkButton>
          )}
        </BottomRow>
      )}
    </Preview>
  );
};

export default PreviewContainer;
